import { Sentiment } from "@/presentation/screens/Sentiment"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/sentiment")({
  component: SentimentOverview,
})

function SentimentOverview() {
  return <Sentiment />
}
