import {
  FilterPill,
  MultiSelectFilterLabel,
  MultiSelectOption,
} from "./components"
import { MultiSelectFilter } from "./MultiSelectFilter"

export interface MultiSelectFilterPillOption extends MultiSelectOption {
  pillLabel?: string
}

type MultiSelectFilterPillProps = {
  title: string
  selectedFilterItems: string[]
  filterOptions: MultiSelectFilterPillOption[]
  onChange: (selectedOptions: string[]) => void
}

export const MultiSelectFilterPill = ({
  title,
  filterOptions,
  selectedFilterItems,
  onChange,
}: MultiSelectFilterPillProps) => (
  <div className="flex gap-6">
    <FilterPill
      label={
        selectedFilterItems.length > 0
          ? `${title} (${selectedFilterItems.length})`
          : title
      }
      isActive={selectedFilterItems.length > 0}
      onClear={() => onChange([])}
      className="max-w-[180px] border-[#333] border"
    >
      {({ close }) => (
        <MultiSelectFilter
          onChange={(selectedOptions) =>
            onChange(selectedOptions.length ? selectedOptions : [])
          }
          onClose={close}
          selectedFilterItems={selectedFilterItems}
          filterOptions={filterOptions}
          title={title}
        />
      )}
    </FilterPill>
  </div>
)
