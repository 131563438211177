import { Brain, Check, Copy } from "lucide-react"
import { useState } from "react"
import { HeadlineAnalysis } from "./AnalysisDialog/Data/HeadlineAnalysisData"
import { AnalysisDialogContent } from "./AnalysisDialog/components/HeadlineAnalysisDialogContent"
import { motion } from "framer-motion"
import { useAuth0 } from "@auth0/auth0-react"
import { useUser } from "@/providers"
import { usePostHog } from "posthog-js/react"
import { toast } from "@/hooks/use-toast"
import { toPng } from "html-to-image"
import { format } from "date-fns"
import { headlinesKeywords } from "./AnalysisDialog/enums/HeadlineAnalysisChipEnums"

interface HeadlineItemProps {
  headlineTitle: string
  headlineTime: string
  relativeTime: string
  className?: string
  highlightEnabled?: boolean
}

interface HeadlineAnalysisState {
  isLoading: boolean
  data?: HeadlineAnalysis
  error?: string
}

export const HeadlineItem = ({
  headlineTitle,
  headlineTime,
  relativeTime,
  className = "",
  highlightEnabled = false,
}: HeadlineItemProps) => {
  const [expanded, setExpanded] = useState(false)
  const [analysisState, setAnalysisState] = useState<HeadlineAnalysisState>()
  const [copiedIndex, setCopiedIndex] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const { userData } = useUser()
  const posthog = usePostHog()

  const getHeadlineInformation = async () => {
    if (!headlineTitle) return

    posthog.capture("market_headline_ai_analysis", {
      headline_time: headlineTime,
      headline_title: headlineTitle,
      user: userData?.name,
    })

    setAnalysisState({ isLoading: true })
    setExpanded(true)

    try {
      const response = await fetch(
        `${import.meta.env.VITE_MRKT_SERVER}/ai/headline?text=${headlineTitle}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      )

      if (response.ok) {
        const headlineResponse = await response.json()
        setAnalysisState({ isLoading: false, data: headlineResponse })
      } else {
        const errorMessage = "Failed to analyze headline"
        setAnalysisState({
          isLoading: false,
          error: errorMessage,
        })

        toast({
          title: "Error",
          description: errorMessage,
          variant: "destructive",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      }
    } catch (error) {
      console.error("Failed to analyze headline:", error)
      setAnalysisState({
        isLoading: false,
        error: "Failed to connect to analysis service",
      })

      toast({
        title: "Error",
        description: "Failed to connect to analysis service",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    }
  }

  const copyHeadlineAsImage = async () => {
    setCopiedIndex("copy")

    const wrapper = document.createElement("div")
    wrapper.style.cssText = `
      position: fixed;
      left: -9999px;
      top: -9999px;
      background: black;
      width: 0;
      height: 0;
      overflow: hidden;
    `

    const container = document.createElement("div")
    container.style.cssText = `
      padding: 20px;
      background-color: black;
      border-radius: 12px;
      width: 600px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      position: absolute;
      left: 0;
      top: 0;
    `

    container.innerHTML = `
      <div style="padding: 20px; background-color: black;">
        <div style="
          color: rgb(156, 163, 175);
          font-size: 20px;
          margin-bottom: 10px;
          font-weight: 500;
          letter-spacing: 0.3px;
        ">
          ${relativeTime}
        </div>
        <div style="
          color: white;
          font-size: 22px;
          line-height: 1.3;
          font-weight: 500;
          letter-spacing: -0.01em;
          margin-bottom: 16px;
        ">
          ${headlineTitle}
        </div>
        <div style="
          color: rgb(156, 163, 175);
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 6px;
          padding-top: 12px;
          border-top: 1px solid #333;
        ">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chart-candlestick"><path d="M9 5v4"/><rect width="4" height="6" x="7" y="9" rx="1"/><path d="M9 15v2"/><path d="M17 3v2"/><rect width="4" height="8" x="15" y="5" rx="1"/><path d="M17 13v3"/><path d="M3 3v16a2 2 0 0 0 2 2h16"/></svg>
          Powered by <span style="color: white; font-weight: 600; margin-left: 4px;">MRKT</span>
        </div>
      </div>
    `

    wrapper.appendChild(container)
    document.body.appendChild(wrapper)

    try {
      const dataUrl = await toPng(container, {
        quality: 0.95,
        backgroundColor: "black",
      })

      try {
        const response = await fetch(dataUrl)
        const blob = await response.blob()

        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])

        toast({
          title: "Copied!",
          description: "Headline copied to clipboard as image",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      } catch (clipboardError) {
        const link = document.createElement("a")
        link.href = dataUrl
        link.download = "headline.png"
        link.click()

        toast({
          title: "Downloaded",
          description: "Headline saved as image (clipboard access denied)",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      }
    } catch (error) {
      console.error("Failed to process headline image:", error)
      toast({
        title: "Error",
        description: "Failed to copy headline as image",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } finally {
      document.body.removeChild(wrapper)
      setTimeout(() => setCopiedIndex(null), 2000)
    }
  }

  const copyHeadlineWithAnalysis = async (
    headline: string,
    time: string,
    analysis: HeadlineAnalysis,
  ) => {
    setCopiedIndex("copy-analysis")

    const wrapper = document.createElement("div")
    wrapper.style.cssText = `
      position: fixed;
      left: -9999px;
      top: -9999px;
      background: black;
      width: 0;
      height: 0;
      overflow: hidden;
    `

    const container = document.createElement("div")
    container.style.cssText = `
      padding: 20px;
      background-color: black;
      border-radius: 12px;
      width: 1000px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      position: absolute;
      left: 0;
      top: 0;
    `

    const escapeHtml = (str: string) => {
      return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
    }

    container.innerHTML = `
      <div style="padding: 20px; background-color: black;">
        <div style="
          color: rgb(156, 163, 175);
          font-size: 20px;
          margin-bottom: 10px;
          font-weight: 500;
          letter-spacing: 0.3px;
        ">
          ${time}
        </div>
        <div style="
          color: white;
          font-size: 22px;
          line-height: 1.3;
          font-weight: 500;
          letter-spacing: -0.01em;
          margin-bottom: 16px;
        ">
          ${headline}
        </div>
        <div style="
          color: white;
          font-size: 18px;
          line-height: 1.6;
          margin-top: 16px;
          padding: 20px;
          background: rgb(17, 24, 39);
          border-radius: 12px;
          border: 1px solid rgb(31, 41, 55);
        ">
          <div style="
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 12px;
            color: white;
          ">MRKT AI Analysis</div>
          <div style="color: rgb(209, 213, 219);">
            ${escapeHtml(analysis.summary || "")}
          </div>
          <div style="
            display: flex;
            gap: 8px;
            margin-top: 16px;
            flex-wrap: wrap;
          ">
            ${analysis.assets
              .map(
                (asset) => `
              <div style="
                display: inline-flex;
                align-items: center;
                padding: 8px 14px;
                background: ${
                  asset.impact === "Bullish"
                    ? "rgba(34, 197, 94, 0.15)"
                    : asset.impact === "Bearish"
                      ? "rgba(239, 68, 68, 0.15)"
                      : "rgba(75, 85, 99, 0.15)"
                };
                border: 1px solid ${
                  asset.impact === "Bullish"
                    ? "rgba(34, 197, 94, 0.3)"
                    : asset.impact === "Bearish"
                      ? "rgba(239, 68, 68, 0.3)"
                      : "rgba(75, 85, 99, 0.3)"
                };
                box-shadow: 0 0 10px ${
                  asset.impact === "Bullish"
                    ? "rgba(34, 197, 94, 0.1)"
                    : asset.impact === "Bearish"
                      ? "rgba(239, 68, 68, 0.1)"
                      : "rgba(75, 85, 99, 0.1)"
                };
                border-radius: 9999px;
                color: ${
                  asset.impact === "Bullish"
                    ? "rgb(34, 197, 94)"
                    : asset.impact === "Bearish"
                      ? "rgb(239, 68, 68)"
                      : "rgb(156, 163, 175)"
                };
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.3px;
              ">
                ${escapeHtml(asset.currency)}
              </div>
            `,
              )
              .join("")}
          </div>
        </div>
        <div style="
          color: rgb(156, 163, 175);
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 6px;
          padding-top: 12px;
          border-top: 1px solid #333;
          margin-top: 16px;
        ">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chart-candlestick"><path d="M9 5v4"/><rect width="4" height="6" x="7" y="9" rx="1"/><path d="M9 15v2"/><path d="M17 3v2"/><rect width="4" height="8" x="15" y="5" rx="1"/><path d="M17 13v3"/><path d="M3 3v16a2 2 0 0 0 2 2h16"/></svg>
          Powered by <span style="color: white; font-weight: 600; margin-left: 4px;">MRKT</span>
        </div>
      </div>
    `

    wrapper.appendChild(container)
    document.body.appendChild(wrapper)

    try {
      const dataUrl = await toPng(container, {
        quality: 0.95,
        backgroundColor: "black",
      })

      try {
        const response = await fetch(dataUrl)
        const blob = await response.blob()

        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])

        toast({
          title: "Copied!",
          description: "Headline with AI analysis copied to clipboard as image",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      } catch (clipboardError) {
        const link = document.createElement("a")
        link.href = dataUrl
        link.download = "headline-with-analysis.png"
        link.click()

        toast({
          title: "Downloaded",
          description:
            "Headline with AI analysis saved as image (clipboard access denied)",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      }
    } catch (error) {
      console.error("Failed to process headline image:", error)
      toast({
        title: "Error",
        description: "Failed to copy headline as image",
        variant: "destructive",
        className: "bg-zinc-900 border border-[#333] text-white text-md",
      })
    } finally {
      document.body.removeChild(wrapper)
      setTimeout(() => setCopiedIndex(null), 2000)
    }
  }

  const highlightKeywords = (
    text: string,
    keywords: string[],
    highlightEnabled: boolean,
  ) => {
    if (!highlightEnabled) return text

    const words = text.split(/\s+/)
    return words.map((word, index) => {
      const cleanWord = word
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toUpperCase()
      const matchedKeyword = keywords.find(
        (keyword) =>
          keyword === cleanWord ||
          (keyword.length > 2 && cleanWord.includes(keyword)),
      )
      if (matchedKeyword) {
        return (
          <span key={index} className="font-extrabold text-purple-400">
            {word}{" "}
          </span>
        )
      }
      return word + " "
    })
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1.5">
          <span className="text-md font-medium text-gray-400">
            {format(new Date(headlineTime), "MMM d, yyyy h:mm a")}
            <span className="text-gray-600 mx-1">-</span>
            <span className="text-gray-500">{relativeTime}</span>
          </span>
        </div>
        <div className="flex items-center gap-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <button
            onClick={getHeadlineInformation}
            className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200"
          >
            <Brain className="w-5 h-5 text-purple-400" />
          </button>

          <button
            onClick={copyHeadlineAsImage}
            className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200"
          >
            {copiedIndex === "copy" ? (
              <Check className="w-5 h-5 text-green-400" />
            ) : (
              <Copy className="w-5 h-5 text-purple-400" />
            )}
          </button>

          {analysisState?.data && (
            <button
              onClick={() =>
                copyHeadlineWithAnalysis(
                  headlineTitle,
                  relativeTime,
                  analysisState.data!,
                )
              }
              className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center gap-1"
            >
              {copiedIndex === "copy-analysis" ? (
                <Check className="w-5 h-5 text-green-400" />
              ) : (
                <>
                  <Copy className="w-5 h-5 text-purple-400" />
                  <span className="text-xs text-gray-400">With AI</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <p className="text-[15px] leading-relaxed text-gray-100 font-normal">
        {highlightKeywords(headlineTitle, headlinesKeywords, highlightEnabled)}
      </p>

      {expanded && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="mt-2 overflow-hidden"
        >
          <AnalysisDialogContent
            headlineTitle={headlineTitle}
            headlineTime={headlineTime}
            headlineAnalysisData={analysisState?.data}
            isLoading={analysisState?.isLoading ?? false}
            onClose={() => setExpanded(false)}
          />
        </motion.div>
      )}
    </div>
  )
}
