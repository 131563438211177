import { InformationCircleIcon } from "@heroicons/react/24/solid"
import * as Tooltip from "@radix-ui/react-tooltip"

interface TooltipIconProps {
  tooltipText: string
  tooltipTitle?: string
  className?: string
}

export const TooltipIcon = ({
  tooltipText,
  tooltipTitle,
  className = "h-5 w-5 text-gray-500",
}: TooltipIconProps) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="inline-flex items-center cursor-help hover:text-purple-400 transition-colors">
            <InformationCircleIcon className={className} />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade
              data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade
              data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade
              data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade
              backdrop-blur-lg bg-black/50 text-white p-3 rounded-lg shadow-lg border border-white/10 w-[350px] h-auto
              select-none"
            side="top"
            align="center"
            sideOffset={8}
            collisionPadding={16}
            avoidCollisions={true}
          >
            {tooltipTitle && (
              <div className="text-purple-400 text-lg font-medium mb-2">
                {tooltipTitle}
              </div>
            )}
            <div className="h-px bg-white/10 mb-2" />
            <div className="text-zinc-400 text-md leading-relaxed">
              {tooltipText}
            </div>
            <Tooltip.Arrow className="fill-black/30" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
