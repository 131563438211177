import React from "react"
import { useQuery } from "@tanstack/react-query"
import { format, subDays } from "date-fns"
import { SentimentChart } from "./SentimentChart"
import { OverviewChartTypes } from "../constants/OverviewChartConstants"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const API_BASE_URL = "https://api.twelvedata.com"

const fetchRiskAssetsTimeSeries = async () => {
  const endDate = new Date()
  const startDate = subDays(endDate, 30)
  const symbols = ["VIX", "TNX", "XAU/USD", "DXY", "BTC/USD", "XBR/USD"] // Changed CL to WTI
  const url = new URL(`${API_BASE_URL}/time_series`)

  url.searchParams.append("symbol", symbols.join(","))
  url.searchParams.append("interval", "4h")
  url.searchParams.append("apikey", import.meta.env.VITE_TWELVE_DATA_API_KEY)
  url.searchParams.append("start_date", format(startDate, "yyyy-MM-dd"))
  url.searchParams.append("end_date", format(endDate, "yyyy-MM-dd"))

  const response = await fetch(url.toString())
  if (!response.ok) {
    throw new Error("Network response was not ok")
  }
  return response.json()
}

export const RiskAssetsSentiment: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["riskAssetsData"],
    queryFn: () => fetchRiskAssetsTimeSeries(),
  })

  if (isLoading) {
    return (
      <div className="flex flex-wrap justify-between gap-4 mb-4">
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="h-72 w-full sm:w-[calc(50%-8px)] lg:w-[calc(33.333%-10.67px)]"
          >
            <Skeleton
              height="100%"
              className="mb-2"
              baseColor="#4B5563"
              highlightColor="#6B7280"
            />
          </div>
        ))}
      </div>
    )
  }

  if (error) return <div>Error fetching data</div>
  if (!data) return <div>No data available</div>

  return (
    <div className="flex flex-wrap justify-between gap-4 mb-4">
      {Object.entries(data).map(([symbol, symbolData]) => (
        <div
          key={symbol}
          className="h-72 cursor-pointer w-full sm:w-[calc(50%-8px)] lg:w-[calc(33.333%-10.67px)]"
        >
          <SentimentChart
            symbol={symbol}
            title={getSymbolTitle(symbol)}
            type={getSymbolType(symbol)}
            data={symbolData}
          />
        </div>
      ))}
    </div>
  )
}

function getSymbolTitle(symbol: string): string {
  const titles: { [key: string]: string } = {
    VIX: "VIX (Volatility Index)",
    TNX: "US 10-Year Treasury Yield",
    GLD: "Gold",
    DXY: "US Dollar Index",
    "BTC/USD": "Bitcoin",
    "XBR/USD": "Crude Oil (WTI)", // Updated title for WTI
  }
  return titles[symbol] || symbol
}

function getSymbolType(symbol: string): OverviewChartTypes {
  const riskOffAssets = ["VIX", "DXY"]
  return riskOffAssets.includes(symbol)
    ? OverviewChartTypes.Bearish
    : OverviewChartTypes.Bullish
}
