import clsx from "clsx"

export const SentimentBadge = (
  badge: HeadlineSentiment,
  className?: string,
) => {
  switch (badge) {
    case HeadlineSentiment.bullish:
      return (
        <span
          className={clsx(
            className,
            "inline-flex items-center rounded-2xl bg-transparent px-2 py-1 text-sm font-medium text-green-400 ring-1 ring-inset ring-green-500/40",
          )}
        >
          Bullish
        </span>
      )
    case HeadlineSentiment.bearish:
      return (
        <span
          className={clsx(
            className,
            "inline-flex items-center rounded-2xl bg-transparent px-2 py-1 text-sm font-medium text-red-400 ring-1 ring-inset ring-red-500/40",
          )}
        >
          Bearish
        </span>
      )
  }
}

export enum HeadlineSentiment {
  bullish = "Bullish",
  bearish = "Bearish",
}
