export function OverviewAnalysis() {
  return (
    <div className="text-gray-400 space-y-4">
      <p className="text-md leading-relaxed">
        The Fed has shifted to a more dovish stance, cutting rates by 50 basis
        points. Attention has moved from inflation to the labor market, as
        unemployment has risen to 4.3%. The Fed now prioritizes labor market
        stability, possibly leading to further monetary easing. Labor data will
        influence future policy more than inflation unless inflation data
        surprises significantly.
      </p>
      <p className="text-md leading-relaxed">
        This policy shift has kept risk assets like equities and gold bullish.
        Gold benefits from the dovish Fed and geopolitical tensions in the
        Middle East, while equities rise on expectations of continued easing of
        monetary policy. The U.S. dollar is under pressure with Fed's dovish
        stance, with global risks adding further uncertainty.
      </p>
    </div>
  )
}
