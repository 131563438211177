import { createFileRoute } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { LoadingScreen } from "@/providers"

export const Route = createFileRoute("/")({
  component: IndexRoute,
})

function IndexRoute() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        window.location.href = "/dashboard"
      } else {
        loginWithRedirect({
          appState: {
            returnTo: "/dashboard",
          },
        })
      }
    }
  }, [isAuthenticated, isLoading])

  return <LoadingScreen />
}
