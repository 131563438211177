export const currentTime = new Date().getUTCHours()
export const today = new Date().getDay()
export const isWeekday = today !== 0 && today !== 6

// Helper function to check if the current time is within a given range
const isTimeInRange = (
  start: number,
  end: number,
  current: number,
): boolean => {
  if (start <= end) {
    return current >= start && current < end
  } else {
    return current >= start || current < end
  }
}

// Function to check which trading sessions are open
export const getOpenTradingSessions = (): { [key: string]: boolean } => {
  const now = new Date()
  const currentDay = now.getUTCDay() // 0 (Sunday) to 6 (Saturday)
  const currentHour = now.getUTCHours()
  const currentMinute = now.getUTCMinutes()
  const currentTime = currentHour + currentMinute / 60

  // Check if the forex market is open (Sunday 5:00 pm ET to Friday 5:00 pm ET)
  const isMarketOpen =
    (currentDay > 0 && currentDay < 5) ||
    (currentDay === 0 && currentTime >= 21) ||
    (currentDay === 5 && currentTime < 21)

  if (!isMarketOpen) {
    return {
      sydney: false,
      tokyo: false,
      london: false,
      newYork: false,
    }
  }

  return {
    sydney: isTimeInRange(21, 6, currentTime),
    tokyo: isTimeInRange(0, 9, currentTime),
    london: isTimeInRange(7, 16, currentTime),
    newYork: isTimeInRange(13, 22, currentTime),
  }
}

// You can keep or update the existing active state functions if needed
export const americasActive = isWeekday && currentTime > 13 && currentTime < 20
export const europeActive = isWeekday && currentTime > 12 && currentTime < 20
export const asiaActive = isWeekday && currentTime > 13 && currentTime < 20
export const oceaniaActive = isWeekday && (currentTime >= 14 || currentTime < 8)
