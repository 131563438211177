import { createRootRoute, Outlet, useNavigate } from "@tanstack/react-router"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"

function NotFound() {
  return (
    <div className="bg-black min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-white mb-4">404</h1>
        <p className="text-gray-400 mb-6">Page not found</p>
        <a
          href="/dashboard"
          className="text-red-500 hover:text-red-400 underline"
        >
          Go to Dashboard
        </a>
      </div>
    </div>
  )
}

export const Route = createRootRoute({
  component: RootComponent,
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  notFoundComponent: NotFound,
})
function RootComponent() {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return (
      <div className="bg-black min-h-screen">
        <h1 className="text-white text-center">Loading...</h1>
      </div>
    )
  }

  return (
    <div className="bg-black min-h-screen">
      <Outlet />
    </div>
  )
}
