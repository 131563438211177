import { useState, useEffect } from "react"
import { OverviewAnalysis } from "./components/OverviewAnalysis"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { UsSentiment } from "./components/UsSentiment"
import { GlobalEquitiesSentiment } from "./components/GlobalEquitiesSentiment"
import { RiskAssetsSentiment } from "./components/RiskAssetsSentiment"
import { CurrenciesSentiment } from "./components/CurrenciesSentiment"

type SentimentType = "US" | "Global Equities" | "Risk" | "Currencies"

export const Sentiment = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedSentiment, setSelectedSentiment] =
    useState<SentimentType>("US")

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  const sentimentTypes: SentimentType[] = [
    "US",
    "Global Equities",
    "Risk",
    "Currencies",
  ]

  const renderSentimentComponent = () => {
    switch (selectedSentiment) {
      case "US":
        return <UsSentiment />
      case "Global Equities":
        return <GlobalEquitiesSentiment />
      case "Risk":
        return <RiskAssetsSentiment />
      case "Currencies":
        return <CurrenciesSentiment />
      default:
        return null
    }
  }

  return (
    <div className="bg-black min-h-screen items-center flex flex-col items-start p-8">
      <h1 className="text-4xl font-light text-white mb-6">MRKT Sentiment</h1>
      <div className="px-2 pb-8 flex space-x-4 overflow-x-auto">
        {sentimentTypes.map((sentiment) => (
          <button
            key={sentiment}
            className={`flex items-center justify-center px-3 w-[150px] py-1 rounded-full text-white transition-colors duration-200 ${
              selectedSentiment === sentiment
                ? "bg-[#4a4749]"
                : "bg-[#201f21] hover:bg-gray-700"
            }`}
            onClick={() => setSelectedSentiment(sentiment)}
          >
            <span>{sentiment}</span>
          </button>
        ))}
      </div>
      <div className="w-full max-w-7xl mb-8">
        {renderSentimentComponent()}
        <div className="relative z-10 bg-gradient-to-br from-gray-900 to-black rounded-xl p-8 shadow-lg mt-8">
          <h2 className="text-2xl font-light text-white mb-4">MRKT Analysis</h2>
          <div className="flex flex-col lg:flex-row justify-between">
            {isLoading ? (
              <>
                <div className="w-full lg:w-2/3 pr-0 lg:pr-8 mb-8 lg:mb-0">
                  <Skeleton
                    count={5}
                    height={40}
                    className="mb-2"
                    baseColor="#4B5563"
                    highlightColor="#6B7280"
                  />
                </div>
                <div className="w-full lg:w-1/3">
                  <Skeleton
                    count={1}
                    height={200}
                    className="mb-2"
                    baseColor="#4B5563"
                    highlightColor="#6B7280"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="w-full lg:w-2/3 pr-0 lg:pr-8 mb-8 lg:mb-0">
                  <OverviewAnalysis />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
