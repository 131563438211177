import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { LoadingScreen, useUser } from "@/providers"
import { OnboardingScreen } from "@/presentation"

export const Route = createFileRoute("/onboarding")({
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { userData, isLoading: isUserLoading } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
      // Redirect to login if not authenticated
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: "/onboarding",
          },
        })
      }

      // Redirect to dashboard if user has already completed onboarding
      if (isAuthenticated && !isUserLoading && userData?.hasOnboarded) {
        navigate({ to: "/dashboard" })
      }

      // Redirect to checkout if user is not subscribed
      if (isAuthenticated && !isUserLoading && !userData?.isSubscribed) {
        navigate({ to: "/checkout" })
      }
    }, [isLoading, isAuthenticated, userData, isUserLoading])

    // Show loading screen while checking auth status or user data
    if (isLoading || isUserLoading || !isAuthenticated) {
      return <LoadingScreen />
    }

    // Only show onboarding if user is authenticated, subscribed, and hasn't completed onboarding
    if (isAuthenticated && userData?.isSubscribed && !userData?.hasOnboarded) {
      return <OnboardingScreen />
    }

    // This return is just for TypeScript - the useEffect redirects should handle all cases
    return null
  },
})
