import clsx from "clsx"

import { FaCheck } from "react-icons/fa6"

/**
 * A utility helper to set a property as required
 *
 * @example
 * type UserWithName = WithRequired<User, 'name'>
 */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

type CheckboxProps = WithRequired<
  JSX.IntrinsicElements["input"],
  "id" | "onChange"
>

export const Checkbox = ({ className, ...props }: CheckboxProps) => {
  const isChecked = props.checked
  return (
    <span className={clsx("items-right relative flex", className)}>
      <input
        {...props}
        className="h-[1.25rem] w-[1.25rem] appearance-none rounded border-2 border-[#4a4749] outline-none checked:border-transparent checked:bg-blue-500 focus:outline-none"
        type="checkbox"
      />
      <FaCheck
        className={clsx("pointer-events-none absolute p-1", {
          "text-white": isChecked,
          "text-transparent": !isChecked,
        })}
        size="1.25rem"
      />
    </span>
  )
}
