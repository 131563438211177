import { InterestRates, SessionVolume } from "./components"
import { EconomicCalendar } from "./components/EconomicCalendar"
import { MarketHeadlines } from "./components/MarketHeadlines"
import { Map } from "./components/Map"
import "simplebar-react/dist/simplebar.min.css"
import { CentralBankEvents } from "./components/CentralBankEvents/CentralBankEvents"
import { useUser } from "@/providers"
import { useState, useCallback } from "react"

// Component to display headlines
function getFormattedDate(): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const date = new Date()
  const formattedDate = date.toLocaleDateString("en-US", options)

  // Custom suffix for day
  const day = date.getDate()
  const daySuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th"
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`)
}

type HomeScreenHeaderProps = {
  name: string
  isPrioritized: boolean
  onToggleView: () => void
}

const HomescreenHeader = ({
  name,
  isPrioritized,
  onToggleView,
}: HomeScreenHeaderProps) => {
  const [isTransitioning, setIsTransitioning] = useState(false)

  const onToggleFocus = useCallback(() => {
    setIsTransitioning(true)
    onToggleView()
    setTimeout(() => setIsTransitioning(false), 200)
  }, [onToggleView])

  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-3xl font-light text-white">Welcome, {name}</h1>
        <h3 className="text-xl font-light text-gray-500 mt-1">
          {getFormattedDate()}
        </h3>
      </div>
      <button
        onClick={onToggleFocus}
        disabled={isTransitioning}
        aria-label={
          isPrioritized ? "Switch to full view" : "Switch to focus mode"
        }
        aria-pressed={isPrioritized}
        className={`
          relative group flex items-center gap-3 px-5 py-2.5
          bg-zinc-900/50 backdrop-blur-sm
          border border-[#333]
          rounded-md
          text-zinc-400
          transition-all duration-200
          hover:bg-zinc-800/50 hover:border-zinc-700/50 hover:text-white
          active:scale-95
          disabled:opacity-50 disabled:cursor-not-allowed disabled:active:scale-100
          shadow-lg shadow-black/20
        `}
      >
        <span className="text-md font-medium tracking-wide">
          {isPrioritized ? "Dashboard View" : "Prioritized View"}
        </span>
        <div
          className={`
          w-4 h-4 transition-transform duration-200
          ${isPrioritized ? "rotate-180" : "rotate-0"}
        `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4"
          >
            <path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7" />
          </svg>
        </div>
      </button>
    </div>
  )
}

export const HomeScreen = () => {
  const { userData } = useUser()
  const [isPrioritized, setIsPrioritized] = useState(true)

  const toggleView = () => {
    setIsPrioritized(!isPrioritized)
  }

  return (
    <div className="flex flex-col min-h-screen bg-black">
      <HomescreenHeader
        name={userData?.name ?? "Trader"}
        isPrioritized={isPrioritized}
        onToggleView={toggleView}
      />
      <div
        className={`grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-4 gap-4 mt-6 ${isPrioritized ? "flex-1" : ""}`}
      >
        <div
          className={`bg-obsidian rounded-lg lg:col-span-2 border border-[#333] p-4 ${
            isPrioritized ? "h-[calc(100vh-120px)]" : "h-[700px]"
          }`}
        >
          <MarketHeadlines />
        </div>
        <div
          className={`bg-obsidian rounded-lg lg:col-span-2 border border-[#333] p-4 ${
            isPrioritized ? "h-[calc(100vh-120px)]" : "h-[700px]"
          }`}
        >
          <EconomicCalendar />
        </div>
        {!isPrioritized && (
          <>
            <div className="bg-obsidian rounded-lg lg:col-span-1 border border-[#333] p-4 h-[400px]">
              <SessionVolume />
            </div>
            <div className="bg-obsidian rounded-lg lg:col-span-1 border border-[#333] p-4 h-[400px]">
              <CentralBankEvents />
            </div>
            <div className="bg-obsidian rounded-lg lg:col-span-1 border border-[#333] p-4 h-[400px]">
              <Map />
            </div>
            <div className="bg-obsidian rounded-lg lg:col-span-1 border border-[#333] p-4 h-[400px]">
              <InterestRates />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
