import React, { Component, ReactNode } from "react"

type ErrorBoundaryProps = {
  children: ReactNode
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void
  fallbackType?: "auth" | "route" | "generic"
}

type ErrorBoundaryState = {
  hasError: boolean
  error: Error | null
}

type ErrorFallbackProps = {
  error: Error | null
  fallbackType?: "auth" | "route" | "generic"
}

const ErrorFallback = ({
  error,
  fallbackType = "generic",
}: ErrorFallbackProps) => (
  <div className="bg-black min-h-screen flex items-center justify-center">
    <div className="text-center text-white">
      <h2 className="text-xl mb-4">
        {fallbackType === "auth" && "Authentication Error"}
        {fallbackType === "route" && "Navigation Error"}
        {fallbackType === "generic" && "Something went wrong"}
      </h2>
      <p className="text-red-400">{error?.message}</p>
      {fallbackType === "auth" && (
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-red-500 rounded"
        >
          Retry
        </button>
      )}
    </div>
  </div>
)

export class AppErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(
      `${this.props.fallbackType || "Generic"} error:`,
      error,
      errorInfo,
    )
    this.props.onError?.(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorFallback
          error={this.state.error}
          fallbackType={this.props.fallbackType}
        />
      )
    }
    return this.props.children
  }
}

// Export convenience components for specific use cases
export const AuthErrorBoundary = (
  props: Omit<ErrorBoundaryProps, "fallbackType">,
) => <AppErrorBoundary {...props} fallbackType="auth" />

export const RouteErrorBoundary = (
  props: Omit<ErrorBoundaryProps, "fallbackType">,
) => <AppErrorBoundary {...props} fallbackType="route" />
