import { HeadlineAnalysisChipEnums } from "../enums/HeadlineAnalysisChipEnums"

interface HeadlineAnalysisDialogChipProps {
  label: String
  type: HeadlineAnalysisChipEnums
}

export const HeadlineAnalysisDialogChip: React.FC<
  HeadlineAnalysisDialogChipProps
> = ({ label, type }) => {
  const baseClasses =
    "inline-flex items-center rounded-full px-4 py-2 text-sm font-semibold shadow-md transition-all duration-200 ease-in-out"

  switch (type) {
    case HeadlineAnalysisChipEnums.General:
      return (
        <span
          className={`${baseClasses} bg-gray-800 text-gray-200 hover:bg-gray-700`}
        >
          {label}
        </span>
      )
    case HeadlineAnalysisChipEnums.Bullish:
      return (
        <span
          className={`${baseClasses} bg-green-900 text-green-100 hover:bg-green-800`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
            />
          </svg>
          {label}
        </span>
      )
    case HeadlineAnalysisChipEnums.Bearish:
      return (
        <span
          className={`${baseClasses} bg-red-900 text-red-100 hover:bg-red-800 ml-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
          {label}
        </span>
      )
  }
}
