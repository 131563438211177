import {
  MultiSelectFilterPill,
  MultiSelectFilterPillOption,
} from "./MultiSelectFilterPill"

export interface CountriesMultiSelectFilterProps {
  selectedStatuses: string[]
  onChange: (selectedOptions: string[]) => void
  title: string
  filterOptions: MultiSelectFilterPillOption[]
}

export const CountriesMultiSelectFilter = ({
  selectedStatuses,
  onChange,
  title,
  filterOptions,
}: CountriesMultiSelectFilterProps) => (
  <MultiSelectFilterPill
    title={title}
    filterOptions={filterOptions}
    selectedFilterItems={selectedStatuses}
    onChange={(selectedOptions) => onChange(selectedOptions)}
  />
)
