import { Popover } from "@headlessui/react"
import { useAuth0 } from "@auth0/auth0-react"
import { Settings, LogOut } from "lucide-react"

export default function AccountDropdown() {
  const { logout, user } = useAuth0()
  const userName = user?.name
  const userEmail = user?.email

  const handleBillingClick = () => {
    window.location.href = import.meta.env.VITE_STRIPE_BILLING_LINK
  }

  const handleLogout = () => {
    console.log("logging out")
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: true,
      },
    })
  }

  return (
    <div className="hidden sm:block">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button className="flex rounded-full bg-obsidian text-sm focus:outline-none focus:ring-1 focus:ring-[#333] focus:ring-offset-2 focus:ring-offset-gray-800 p-2 cursor-pointer select-none">
              <span className="sr-only">Open user menu</span>
              <div className="h-8 w-8">
                <svg
                  className="h-8 w-8 rounded-full"
                  fill="white"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </div>
            </Popover.Button>

            <Popover.Panel className="absolute right-0 z-10 mt-3 w-56 origin-top-right rounded-md bg-obsidian text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-[#333]">
              <div className="py-1">
                <div className="px-4 py-3">
                  <p className="text-md font-medium leading-none">{userName}</p>
                </div>
                <div className="border-t border-[#333]" />
                <button
                  onClick={() => {
                    handleBillingClick()
                    close()
                  }}
                  className="flex w-full items-center px-4 py-2 text-sm hover:bg-[#333] cursor-pointer"
                >
                  <Settings className="mr-2 h-4 w-4" />
                  <span>Account settings</span>
                </button>
                <button
                  onClick={() => {
                    handleLogout()
                    close()
                  }}
                  className="flex w-full items-center px-4 py-2 text-sm hover:bg-[#333] cursor-pointer"
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </button>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}
