import { addDays } from "date-fns"
import { DateRange } from "react-day-picker"

import { Calendar } from "@/components/ui/calendar"
import { FilterPill } from "./components"
import { useCallback, useState } from "react"
import { FiX } from "react-icons/fi"

type DatePickerFilterPillProps = {
  title: string
  selectedStartDate: string
  selectedEndDate?: string
  onChange: (dateRange?: DateRange) => void
  onClear: () => void
}

export const DatePickerFilterPill = ({
  title,
  selectedStartDate,
  onChange,
  onClear,
}: DatePickerFilterPillProps) => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(),
    to: addDays(new Date(), 7),
  })
  const [active, setActive] = useState(false)

  const onDateChange = () => {
    onChange(date)
    setActive(true)
  }

  const onDateClear = () => {
    onClear()
    setDate(undefined)
  }

  const isActive = useCallback(() => {
    if (date?.to !== undefined && active) {
      return true
    } else {
      return false
    }
  }, [date?.to, active])
  return (
    <div className="flex gap-6">
      <FilterPill
        label={title}
        isActive={isActive()}
        onClear={onDateClear}
        className="max-w-[180px] border-[#333] border"
      >
        {({ close }) => (
          <div className="w-auto rounded-lg bg-obsidian text-white border-2 border-[#201f21] shadow-md">
            <div className="flex pr-2.5 pl-4 pt-4 pb-1.5">
              <h2 className="flex-grow mt-1 text-[20px] font-bold">{title}</h2>
              <button
                data-testid="close-button"
                className="!p-2"
                onClick={close}
              >
                <FiX size="1.5em" />
              </button>
            </div>
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
            />
            <div className="flex justify-end gap-2 px-4 py-4 shadow-[0_-4px_8px_0_rgba(0,0,0,0.08)]">
              <button
                className="rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-gray-400 hover:bg-gray-200"
                disabled={!selectedStartDate}
                onClick={onDateClear}
              >
                Clear
              </button>
              <button
                className="rounded-md bg-purple-400 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-purple-500 shadow-sm"
                onClick={() => {
                  onDateChange()
                  close()
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </FilterPill>
    </div>
  )
}
