import { useState } from "react"
import { FiX } from "react-icons/fi"
import { MultiSelect, MultiSelectOption } from "./components"

export interface MultiSelectFilterProps {
  onChange: (selectedOptions: string[]) => void
  onClose: () => void
  selectedFilterItems: string[]
  filterOptions: MultiSelectOption[]
  title: string
}

export const MultiSelectFilter = ({
  onChange,
  onClose,
  selectedFilterItems,
  filterOptions,
  title,
}: MultiSelectFilterProps) => {
  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(selectedFilterItems)

  const onToggle = (id: string) => {
    if (id === "select-all") {
      if (selectedOptions.length === filterOptions.length) {
        setSelectedOptions([])
      } else {
        const allValues = filterOptions.map((option) => option.value)
        setSelectedOptions(allValues)
      }
      return
    }

    setSelectedOptions((prevSelected) => {
      const newSelectedOptions = [...prevSelected]
      if (newSelectedOptions.includes(id)) {
        return newSelectedOptions.filter((item) => item !== id)
      } else {
        newSelectedOptions.push(id)
        return newSelectedOptions
      }
    })
  }

  const handleApply = () => {
    onChange(selectedOptions.sort())
    onClose()
  }

  const handleClear = () => {
    setSelectedOptions([])
  }

  const optionsWithSelectAll = [
    {
      label: "Select All",
      value: "select-all",
      selected: selectedOptions.length === filterOptions.length,
    },
    ...filterOptions,
  ]

  return (
    <div className="w-[300px] rounded-lg bg-obsidian border border-[#333] shadow-md">
      <div className="flex pr-2.5 pl-4 pt-2 pb-1.5 text-white border-b border-[#333]">
        <h2 className="flex-grow mt-1 text-[20px] font-bold">{title}</h2>
        <button data-testid="close-button" className="!p-2" onClick={onClose}>
          <FiX size="1.5em" />
        </button>
      </div>
      <MultiSelect
        options={optionsWithSelectAll}
        selectedOptions={selectedOptions}
        onToggle={onToggle}
        className="max-h-479px bg-obsidian text-white p-2"
      />
      <div className="flex justify-end gap-2 p-4 border-t border-[#333]">
        <button
          className="rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-2 ring-inset ring-gray-400 hover:bg-gray-200"
          disabled={!selectedOptions.length}
          onClick={handleClear}
        >
          Clear
        </button>
        <button
          className="rounded-md bg-violet-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-600"
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  )
}
