import moment from "moment"

export interface HourlyData {
  time: number
  utcTime?: number
  localTime?: number
  volatility: number
  activeSessions: string
  events: string
  sessionOpen: boolean
  specialOpen: string | null
}

export interface Session {
  name: string
  start: number
  end: number
  openImpact: number
}

export const volatilityMap: Record<EconomicEvent["volatility"], number> = {
  NONE: 0,
  LOW: 0.5,
  MEDIUM: 1,
  HIGH: 1.5,
}

export interface EconomicEvent {
  id: string
  dateUtc: string
  name: string
  countryCode: string
  currencyCode: string
  volatility: "NONE" | "LOW" | "MEDIUM" | "HIGH"
}

export const sessions: Session[] = [
  { name: "Sydney", start: 21, end: 6, openImpact: 0.5 },
  { name: "Tokyo", start: 0, end: 9, openImpact: 1 },
  { name: "London", start: 7, end: 16, openImpact: 2.5 },
  { name: "New York", start: 13, end: 22, openImpact: 2.5 },
  { name: "NYSE", start: 14, end: 21, openImpact: 2 },
]

const getSessions = () => {
  const currentDate = new Date()
  const offsetInMinutes = currentDate.getTimezoneOffset()
  const offsetInHours = offsetInMinutes / 60
  const isDST = moment().isDST() // Using moment.js for more accurate DST detection

  return sessions.map((session) => {
    let adjustedStart = session.start
    let adjustedEnd = session.end

    // Adjust for DST if necessary
    if (isDST && (session.name === "New York" || session.name === "NYSE")) {
      adjustedStart = (session.start - 1 + 24) % 24
      adjustedEnd = (session.end - 1 + 24) % 24
    }

    const localTimeStart = (adjustedStart - offsetInHours + 24) % 24
    const localTimeEnd = (adjustedEnd - offsetInHours + 24) % 24
    return {
      ...session,
      localTimeStart,
      localTimeEnd,
    }
  })
}

export const generateHourlyData = (events: EconomicEvent[]): HourlyData[] => {
  const currentDate = new Date()
  const offsetInMinutes = currentDate.getTimezoneOffset()
  const offsetInHours = offsetInMinutes / 60

  const localSessions = getSessions()

  const data: HourlyData[] = []
  for (let i = 0; i < 24; i++) {
    const utcTime = (i + 21) % 24
    const localTime = (utcTime - offsetInHours + 24) % 24

    const activeSessions = localSessions.filter(
      (session) =>
        (session.localTimeStart <= localTime &&
          localTime < session.localTimeEnd) ||
        (session.localTimeStart > session.localTimeEnd &&
          (localTime >= session.localTimeStart ||
            localTime < session.localTimeEnd)),
    )

    let sessionVolatility = activeSessions.length * 0.5
    let sessionOpen = false
    let specialOpen: string | null = null

    // Add session open impacts
    localSessions.forEach((session) => {
      if (Math.floor(session.localTimeStart) === Math.floor(localTime)) {
        sessionVolatility += session.openImpact
        sessionOpen = true
      }
      // Special case for NYSE open at 9:30 local time
      if (
        session.name === "NYSE" &&
        Math.floor(session.localTimeStart) + 0.5 === Math.floor(localTime)
      ) {
        sessionVolatility += session.openImpact * 0.5
        specialOpen = "NYSE"
      }
    })

    // // Add COMEX open at 8:30 AM EST
    // if (utcTime === 13) {
    //   specialOpen = "COMEX"
    // }

    // Maintain higher volume for 6-7 AM UTC and smooth increase for London pre-market
    if (utcTime >= 6 && utcTime < 8) {
      sessionVolatility = Math.max(sessionVolatility, 1.5 + (utcTime - 6) * 0.5)
    }

    // London open (3 AM EST) should have similar volume to NY open (8 AM EST)
    if (utcTime === 8) {
      sessionVolatility = Math.max(sessionVolatility, 3)
    }

    // Add higher volume for 7 AM EST (12:00 UTC)
    if (utcTime === 12) {
      sessionVolatility = Math.max(sessionVolatility, 2.8)
    }

    // Maintain higher volume between 9-11 AM UTC
    if (utcTime >= 9 && utcTime <= 11) {
      sessionVolatility = Math.max(sessionVolatility, 2.5)
    }

    // New York open (13:00 UTC)
    if (utcTime === 13) {
      sessionVolatility = Math.max(sessionVolatility, 3)
    }

    // Smooth transition from 9-10 AM EST
    if (utcTime === 14) {
      sessionVolatility = Math.max(sessionVolatility, 3.2)
    } else if (utcTime === 15) {
      sessionVolatility = Math.max(sessionVolatility, 3.8)
    } else if (utcTime === 16) {
      sessionVolatility = Math.max(sessionVolatility, 3.5)
    } else if (utcTime === 17) {
      sessionVolatility = Math.max(sessionVolatility, 3.4)
    }

    // Increase volatility for high volume hours (13:00 to 18:00 UTC)
    if (utcTime >= 13 && utcTime < 18) {
      sessionVolatility += 0.5
    }

    const hourEvents = events.filter((event) => {
      const eventDate = new Date(event.dateUtc)
      const eventUTCHour = eventDate.getUTCHours()
      return eventUTCHour === utcTime
    })

    const eventVolatility = hourEvents.reduce(
      (sum, event) => sum + volatilityMap[event.volatility],
      0,
    )

    // Add hourly data including both UTC and local time
    data.push({
      time: i,
      utcTime: utcTime,
      localTime: localTime,
      volatility: Math.min(sessionVolatility + eventVolatility, 5),
      activeSessions: activeSessions.map((s) => s.name).join(", "),
      events: hourEvents.map((e) => `${e.name} (${e.currencyCode})`).join(", "),
      sessionOpen,
      specialOpen,
    })
  }

  // Sort the data array based on the 'time' property
  return data.sort((a, b) => a.time - b.time)
}
