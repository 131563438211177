import { ReactNode, useState, useEffect } from "react"
import { Auth0Provider } from "@auth0/auth0-react"

type AuthProviderProps = {
  children: ReactNode
}

export const LoadingScreen = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const increment = Math.max(0.5, (100 - prev) * 0.05)
        return Math.min(prev + increment, 99.9)
      })
    }, 10)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black z-[9999]">
      <div className="w-64 h-0.5 bg-gray-900 rounded-full overflow-hidden">
        <div
          className="h-full bg-white transition-all duration-200 ease-in-out"
          style={{
            width: `${progress}%`,
            transition: "width 200ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        />
      </div>
    </div>
  )
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID
  const mrktServer = import.meta.env.VITE_AUTH0_AUDIENCE
  const [isInitialized, setIsInitialized] = useState(false)

  if (!(domain && clientId)) {
    return null
  }

  const onRedirectCallback = async (appState: any) => {
    // Check if we're on the callback URL
    const params = new URLSearchParams(window.location.search)
    const hasAuthParams = params.has("code") || params.has("error")

    if (hasAuthParams) {
      if (appState?.returnTo) {
        const baseUrl = appState.returnTo
        const plan = appState.plan
        const fullPath = plan ? `${baseUrl}?plan=${plan}` : baseUrl

        try {
          // Use window.location.replace for Safari compatibility
          window.location.replace(fullPath)
        } catch (error) {
          console.error("Navigation error:", error)
          // Fallback to dashboard if navigation fails
          window.location.replace("/dashboard")
        }
      } else {
        // No appState, redirect to dashboard
        window.location.replace("/dashboard")
      }
    }
  }

  // Handle browser back/forward cache (bfcache)
  useEffect(() => {
    const handlePageShow = (e: PageTransitionEvent) => {
      if (e.persisted) {
        window.location.reload()
      }
    }
    window.addEventListener("pageshow", handlePageShow)
    return () => window.removeEventListener("pageshow", handlePageShow)
  }, [])

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: mrktServer,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage" // For persistence
      useRefreshTokens={true} // For silent auth
      useRefreshTokensFallback={true} // For Safari
    >
      {children}
    </Auth0Provider>
  )
}
