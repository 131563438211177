import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { useMarketHeadlines } from "./MarketHeadlinesProvider"
import { useEconomicCalendar } from "./EconomicCalendarProvider"

export type WebSocketMessage = {
  chatId: number
  messageText: string
}

type MessageHandler = (message: WebSocketMessage) => void

interface WebSocketContextType {
  sendMessage1: (message: string) => void
  sendMessage2: (message: string) => void
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
)

interface WebSocketProviderProps {
  url1: string
  url2: string
  onMessage1: MessageHandler
  onMessage2: MessageHandler
  children: ReactNode
}

export const WebSocketProvider = ({
  url1,
  url2,
  onMessage1,
  onMessage2,
  children,
}: WebSocketProviderProps) => {
  const [socket1, setSocket1] = useState<WebSocket>()
  const [socket2, setSocket2] = useState<WebSocket>()
  const { marketHeadlines, setMarketHeadlines } = useMarketHeadlines()
  const economicCalendar = useEconomicCalendar()

  useEffect(() => {
    const ws1 = new WebSocket(url1)
    setSocket1(ws1)

    ws1.onopen = () => {
      console.log("WebSocket1 connection established.")
    }

    ws1.onmessage = (event) => {
      if (event.data === "ping") {
        ws1.send("pong")
      } else {
        const message = JSON.parse(event.data)
        onMessage1(message)
        setMarketHeadlines([message?.messageText, ...marketHeadlines])
      }
    }

    ws1.onclose = () => {
      console.log("WebSocket1 connection closed.")
    }

    return () => {
      ws1.close()
    }
  }, [url1, onMessage1, marketHeadlines, setMarketHeadlines])

  useEffect(() => {
    const ws2 = new WebSocket(url2)
    setSocket2(ws2)

    ws2.onopen = () => {
      console.log("WebSocket2 connection established.")
    }

    ws2.onmessage = (event) => {
      if (event.data === "ping") {
        ws2.send("pong")
      } else {
        try {
          const message = JSON.parse(event.data)
          if (message && typeof message === "object" && "type" in message) {
            onMessage2(message)
            if (economicCalendar.economicCalendarData) {
              economicCalendar.updateCalendar(message)
            } else {
              console.info("Skipping update - calendar not yet initialized")
            }
          } else {
            console.warn("Received invalid message format:", message)
          }
        } catch (error) {
          console.error("Error processing websocket message:", error)
        }
      }
    }

    ws2.onclose = () => {
      console.log("WebSocket2 connection closed.")
    }

    return () => {
      ws2.close()
    }
  }, [url2, onMessage2, economicCalendar])

  const sendMessage1 = (message: string) => {
    if (socket1?.readyState === WebSocket.OPEN) {
      socket1.send(message)
    } else {
      console.error("WebSocket1 is not open.")
    }
  }

  const sendMessage2 = (message: string) => {
    if (socket2?.readyState === WebSocket.OPEN) {
      socket2.send(message)
    } else {
      console.error("WebSocket2 is not open.")
    }
  }

  return (
    <WebSocketContext.Provider value={{ sendMessage1, sendMessage2 }}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocket = () => {
  const context = useContext(WebSocketContext)
  if (context === undefined) {
    throw new Error("useWebSocket must be used within a WebSocketProvider")
  }
  return context
}
