import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { TooltipIcon } from "@/components/ui/tooltip_icon"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { useMemo, useState } from "react"
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import clsx from "clsx"
import { getCountryFlag } from "../EconomicCalendar/EconomicCalendar"

// type InterestRatesProps = {
//   interestRateData: {
//     id: string
//     dateUtc: string | null
//     periodDateUtc: string | null
//     revised: string | null
//     consensus: string | null
//     previous: string | null
//     ratioDeviation: string | null
//     isPreliminary: boolean
//     isBetterThanExpected: string | null
//   }
// }

type FetchHistoricalDataProps = {
  preliminary: boolean
  amount: string
  dateFromUtc: string
  dateToUtc: string
  getAccessTokenSilently: () => Promise<string>
}

// const centralBankIds = [
//   "9959649d-be62-47f5-bafa-ff2f474b9e13",
//   "7035bb7e-d65f-4e72-a0ba-f77baede0207",
//   "8b3a0864-3ad8-4510-88a5-acaf0dc44ce0",
//   "0e5bd840-1d57-484f-8d30-567bb6332f38",
//   "fcfae951-09a7-449e-b6fe-525e1335aaba",
// ]

export const fetchHistoricalData = async ({
  preliminary,
  amount,
  dateFromUtc,
  dateToUtc,
  getAccessTokenSilently,
}: FetchHistoricalDataProps) => {
  const url = new URL(`${import.meta.env.VITE_MRKT_SERVER}/calendar/rates`)

  url.searchParams.append("preliminary", preliminary.toString())
  url.searchParams.append("amount", amount.toString())
  url.searchParams.append("dateFromUtc", dateFromUtc)
  url.searchParams.append("dateToUtc", dateToUtc)

  try {
    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    })
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`)
    }
    const result = await response.json()
    return result
  } catch (err) {
    if (err instanceof Error) {
      return err.message
    } else {
      return "An unknown error occurred"
    }
  }
}

const centralBankNames = {
  "9959649d-be62-47f5-bafa-ff2f474b9e13": "BOE",
  "7035bb7e-d65f-4e72-a0ba-f77baede0207": "BOC",
  "8b3a0864-3ad8-4510-88a5-acaf0dc44ce0": "BOJ",
  "0e5bd840-1d57-484f-8d30-567bb6332f38": "ECB",
  "fcfae951-09a7-449e-b6fe-525e1335aaba": "FED",
}

const colors = [
  "#3b82f6", // Blue
  "#22c55e", // Green
  "#0ea5e9", // Sky blue
  "#10b981", // Emerald
  "#2563eb", // Royal blue
]

// Add this helper function at the top level
const formatMonthYear = (dateString: string) => {
  // Ensure consistent date parsing across browsers
  const [year, month] = dateString.split("T")[0].split("-")
  const date = new Date(parseInt(year), parseInt(month) - 1)
  return `${date.toLocaleString("default", { month: "short" })} ${date.getFullYear()}`
}

// Add this near your other constant definitions
const centralBankButtons = [
  {
    name: "FED",
    id: "fcfae951-09a7-449e-b6fe-525e1335aaba",
    value: "US",
  },
  {
    name: "BOE",
    id: "9959649d-be62-47f5-bafa-ff2f474b9e13",
    value: "UK",
  },
  {
    name: "ECB",
    id: "0e5bd840-1d57-484f-8d30-567bb6332f38",
    value: "EU",
  },
  {
    name: "BOC",
    id: "7035bb7e-d65f-4e72-a0ba-f77baede0207",
    value: "CA",
  },
  {
    name: "BOJ",
    id: "8b3a0864-3ad8-4510-88a5-acaf0dc44ce0",
    value: "JP",
  },
]

export const InterestRates = () => {
  const { getAccessTokenSilently } = useAuth0()
  const queryParams = useMemo(
    () => ({
      preliminary: true,
      amount: "50",
      dateFromUtc: "2022-01-01T00:00:00.000Z",
      dateToUtc: new Date().toISOString().split("T")[0], // Use only the date part
      getAccessTokenSilently,
    }),
    [],
  )

  const { data, error, isLoading } = useQuery({
    queryKey: ["usInterestRates", queryParams],
    queryFn: () => fetchHistoricalData(queryParams),
  })

  // Add this state to track selected banks
  const [selectedBanks, setSelectedBanks] = useState<string[]>(
    centralBankButtons.map((bank) => bank.id),
  )

  if (isLoading) {
    return (
      <Card className="h-full w-full bg-obsidian border-none">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            <h2 className="text-lg font-medium text-white">Interest Rates</h2>
            <TooltipIcon
              tooltipTitle="Interest Rates"
              tooltipText="Track central bank interest rates across major economies. Stay informed about monetary policy changes that impact global markets."
              className="h-5 w-5 text-gray-400 hover:text-gray-600 text-purple-400"
            />
          </div>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-[300px] rounded-md opacity-10 bg-gray-700" />
        </CardContent>
      </Card>
    )
  }

  if (error || !data) {
    return <div className="text-white">Error loading data</div>
  }

  // Update the data processing section
  const processedData = data.reduce((acc: any[], bankData: any) => {
    const [bankId, rates] = Object.entries(bankData)[0]
    const bankName = centralBankNames[bankId as keyof typeof centralBankNames]
    const globalRates = rates as any[]

    globalRates.forEach((rate: any) => {
      // Use the new formatting function
      const monthYear = formatMonthYear(rate.dateUtc)

      const existingEntry = acc.find((entry) => entry.date === monthYear)
      if (existingEntry) {
        existingEntry[bankName] = parseFloat(rate.actual)
      } else {
        acc.push({
          date: monthYear,
          [bankName]: parseFloat(rate.actual),
        })
      }
    })
    return acc
  }, [])

  // Update the sort function to handle the formatted dates correctly
  processedData.sort((a: any, b: any) => {
    const [monthA, yearA] = a.date.split(" ")
    const [monthB, yearB] = b.date.split(" ")

    const dateA = new Date(Date.parse(`${monthA} 1, ${yearA}`))
    const dateB = new Date(Date.parse(`${monthB} 1, ${yearB}`))

    return dateA.getTime() - dateB.getTime()
  })

  // Add this function to handle bank selection
  const toggleBank = (bankId: string) => {
    setSelectedBanks((prev) => {
      if (prev.includes(bankId)) {
        // Don't allow deselecting if it's the last selected bank
        if (prev.length === 1) return prev
        return prev.filter((id) => id !== bankId)
      }
      return [...prev, bankId]
    })
  }

  return (
    <Card className="h-full w-full bg-inherit border-none p-0">
      <CardHeader className="p-2">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-medium text-white">
            Global Interest Rates
          </h2>
          <TooltipIcon
            tooltipTitle="Global Interest Rates"
            tooltipText="Find trends in central bank policy rates."
            className="h-5 w-5 text-gray-400 hover:text-gray-600 text-purple-400"
          />
        </div>
      </CardHeader>

      {/* Add the bank selection buttons */}
      <div className="px-2 pb-2 flex space-x-2 overflow-x-hidden">
        {centralBankButtons.map((bank) => (
          <button
            key={bank.id}
            onClick={() => toggleBank(bank.id)}
            className={clsx(
              "flex items-center justify-center px-3 py-1 rounded-md text-sm font-medium transition-colors duration-200",
              selectedBanks.includes(bank.id)
                ? "bg-gray-600/50 text-primary-foreground"
                : "bg-[#222] text-white hover:bg-[#333]",
            )}
          >
            <span className="mr-2">{getCountryFlag(bank.value)}</span>
            <span>{bank.name}</span>
          </button>
        ))}
      </div>

      <CardContent className="p-0 h-[calc(100%-2rem)] pr-8">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={processedData}
            margin={{ top: 15, right: 10, left: -15, bottom: 55 }}
          >
            <XAxis
              dataKey="date"
              stroke="#888"
              tick={{ fill: "#888", fontSize: 12 }}
              axisLine={{ stroke: "#333" }}
            />
            <YAxis
              stroke="#888"
              tick={{ fill: "#888", fontSize: 12 }}
              axisLine={{ stroke: "#333" }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#333",
                border: "none",
                borderRadius: "1px",
                fontSize: 12,
              }}
              itemStyle={{ color: "#fff" }}
              labelStyle={{ color: "#888" }}
            />
            <Legend wrapperStyle={{ color: "#888", fontSize: 12 }} />
            {/* Modify the Lines rendering to only show selected banks */}
            {Object.entries(centralBankNames)
              .filter(([id]) => selectedBanks.includes(id))
              .map(([id, bank], index) => (
                <Line
                  key={bank}
                  type="monotone"
                  dataKey={bank}
                  stroke={colors[index]}
                  strokeWidth={1.5}
                  dot={false}
                  connectNulls={true}
                  activeDot={{ r: 4 }}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}
