import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

import centralBank2 from "@/assets/central-bank2.png"
import economicCalendar from "@/assets/economic-calendar.png"
import econCalendarAi from "@/assets/econ-calendar-ai.png"
import volatility from "@/assets/volatility.png"
import { useUpdateUser } from "@/hooks/useUpdateUser"
import { useAuth0 } from "@auth0/auth0-react"
import { toast } from "@/hooks/use-toast"
import { useNavigate } from "@tanstack/react-router"

interface UserProfile {
  name: string
  tradingExperience: "beginner" | "intermediate" | "advanced"
  tradingInstruments: ("forex" | "equities" | "commodities" | "crypto")[]
  fundamentalsExperience: "beginner" | "intermediate" | "advanced"
  referralSource: string
}

interface FeatureDemo {
  type: "headlines" | "calendar" | "central-bank" | "volatility"
  headlines?: {
    time: string
    text: string
    hasAI: boolean
  }[]
  calendar?: {
    image: string
    aiImage: string
  }
  centralBank?: {
    image: string
  }
  volatility?: {
    image: string
  }
}

interface Feature {
  title: string
  description: string
  icon: string
  breakdown: {
    title: string
    points: string[]
    example: string
    demo: FeatureDemo
  }
}

type CalendarTab = "calendar" | "ai-analysis"

const totalSteps = 7
const getProgress = (currentStep: number) => (currentStep / totalSteps) * 100

const ProgressBar = ({ step }: { step: number }) => (
  <div className="absolute top-8 left-1/2 transform -translate-x-1/2 w-[90%] max-w-3xl">
    <div className="flex items-center justify-between mb-2">
      <span className="text-sm font-medium text-gray-400">
        Onboarding Progress
      </span>
      <span className="text-sm font-medium text-white">
        {Math.round(getProgress(step))}%
      </span>
    </div>
    <div className="h-2 bg-[#1C1C28] rounded-full overflow-hidden">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${getProgress(step)}%` }}
        className="h-full bg-gradient-to-r from-zinc-500 to-zinc-700 rounded-full"
        transition={{ duration: 0.5 }}
      />
    </div>
  </div>
)

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <motion.button
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    onClick={onClick}
    className="mb-4 text-gray-400 hover:text-white flex items-center group"
  >
    <svg
      className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform duration-300"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
    Back
  </motion.button>
)

// Update the continueButtonClasses constant
const continueButtonClasses =
  "w-full bg-[#2A2A2A] text-white px-8 py-4 rounded-lg hover:bg-[#3A3A3A] transition-colors font-semibold disabled:opacity-50 disabled:cursor-not-allowed"

export const OnboardingScreen: React.FC = () => {
  const [step, setStep] = useState(1)
  const [selectedFeature, setSelectedFeature] = useState<number | null>(null)
  const [activeTab, setActiveTab] = useState<CalendarTab>("calendar")
  const [userProfile, setUserProfile] = useState<UserProfile>({
    name: "",
    tradingExperience: "beginner",
    tradingInstruments: [],
    fundamentalsExperience: "beginner",
    referralSource: "",
  })

  const [showAIInsight, setShowAIInsight] = useState(false)
  const { updateUser } = useUpdateUser()
  const { user } = useAuth0()
  const navigate = useNavigate()

  const referralOptions = [
    { id: "social_media", label: "Social Media", icon: "📱" },
    { id: "capital_hungry", label: "Capital Hungry", icon: "📈" },
    { id: "search_engine", label: "Search Engine", icon: "🔍" },
    { id: "email_newsletter", label: "Email Newsletter", icon: "✉️" },
    { id: "online_ads", label: "Online Ads", icon: "📢" },
    { id: "blogs_articles", label: "Blogs or Articles", icon: "📰" },
    { id: "affiliate_links", label: "Affiliate Links", icon: "🔗" },
    { id: "review_sites", label: "Review Sites", icon: "⭐" },
  ]

  const completeUserOnboarding = async () => {
    try {
      if (!user?.email) {
        toast({
          title: "Error",
          description: "Failed to save onboarding data",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })
      } else {
        await updateUser({
          email: user?.email,
          updates: {
            name: userProfile.name,
            hasOnboarded: true,
            tradingInfo: {
              tradingExperience: userProfile.tradingExperience,
              tradingInstruments: userProfile.tradingInstruments,
              fundamentalsExperience: userProfile.fundamentalsExperience,
            },
            referralSource: userProfile.referralSource,
          },
        })

        // Wait a bit for the query to refetch
        await new Promise((resolve) => setTimeout(resolve, 1300))

        toast({
          description: "Your preferences has been saved!",
          className: "bg-zinc-900 border border-[#333] text-white text-md",
        })

        navigate({ to: "/dashboard" })
      }
    } catch (error) {
      console.error("Error updating user profile:", error)
    }
  }

  const features: Feature[] = [
    {
      title: "Real-Time Market Impacting Headlines",
      description:
        "Stay ahead with AI-powered market headlines analysis and impact assessment",
      icon: "📰",
      breakdown: {
        title: "AI-Powered Market Moving Headlines",
        points: [
          "Real-time news analysis and impact assessment",
          "Market sentiment analysis for each headline",
          "Potential market impact predictions",
          "Related asset correlation analysis",
          "Historical context and similar events comparison",
        ],
        example:
          "Example: When ECB's Lane speaks about monetary policy, AI analyzes the speech context, potential market impact, and provides actionable insights for traders.",
        demo: {
          type: "headlines",
          headlines: [
            {
              time: "7 hours ago",
              text: "ECB'S LANE: T IS ESSENTIAL THAT WE ARE AGILE IN RESPONDING TO RISKS THAT MIGHT CRYSTALLISE",
              hasAI: true,
            },
            {
              time: "7 hours ago",
              text: "ECB'S LANE: CAREFUL, STEP-BY-STEP STRATEGY ENABLES US TO OBSERVE THE RESPONSES OF THE ECONOMY TO OUR DECISIONS AND CONTINUOUSLY REFINE OUR UNDERSTANDING OF THEIR IMPACTS",
              hasAI: true,
            },
            {
              time: "7 hours ago",
              text: "ECB'S LANE: GRADUALISM IS NOT A UNIVERSAL PRINCIPLE, AS CERTAIN CIRCUMSTANCES DEMAND A MORE FORCEFUL RESPONSE",
              hasAI: true,
            },
          ],
        },
      },
    },
    {
      title: "Economic Calendar",
      description:
        "Get AI insights on upcoming economic events and their potential market impact",
      icon: "📅",
      breakdown: {
        title: "AI-Powered Economic Calendar",
        points: [
          "Predictive impact analysis for economic releases",
          "Historical event performance tracking",
          "Market volatility forecasts around events",
          "Cross-market correlation insights",
          "Automated trade opportunity identification",
        ],
        example:
          "Example: For Initial Jobless Claims, AI provides forecast accuracy, potential market reactions, and historical pattern analysis.",
        demo: {
          type: "calendar",
          calendar: {
            image: economicCalendar,
            aiImage: econCalendarAi,
          },
        },
      },
    },
    {
      title: "Central Bank Events",
      description:
        "Understand monetary policy decisions with AI-driven analysis",
      icon: "🏦",
      breakdown: {
        title: "Central Bank Event Analysis & Breakdowns",
        points: [
          "Policy decision impact analysis",
          "Statement comparison with previous meetings",
          "Key phrase detection and interpretation",
          "Market reaction predictions",
          "Long-term policy trajectory analysis",
        ],
        example:
          "Example: During FOMC meetings, AI analyzes statement changes, identifies policy shifts, and predicts market implications.",
        demo: {
          type: "central-bank",
          centralBank: {
            image: centralBank2,
          },
        },
      },
    },
    {
      title: "Volatility & Volume Predictions",
      description:
        "Make informed decisions with AI-predicted trading volume and price volatility",
      icon: "📊",
      breakdown: {
        title: "Volume & Volatility",
        points: [
          "Session-by-session volume predictions",
          "Volatility pattern recognition",
          "Market liquidity analysis",
          "Trading session breakdown",
          "Risk level assessments",
        ],
        example:
          "Example: AI predicts increased volatility during US session due to multiple high-impact economic releases.",
        demo: {
          type: "volatility",
          volatility: {
            image: volatility,
          },
        },
      },
    },
  ]

  const renderFeatureBreakdown = (feature: Feature) => (
    <div className="w-full">
      <BackButton onClick={() => setSelectedFeature(null)} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="max-w-6xl w-full rounded-xl shadow-2xl border border-[#1E1E1E] h-[calc(100vh-200px)] overflow-hidden"
      >
        <div className="flex flex-col lg:flex-row h-full">
          {/* Left Side - Feature Description */}
          <div className="w-full lg:w-2/5 p-6 lg:p-8 overflow-y-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="space-y-4"
            >
              <h1 className="text-2xl lg:text-3xl font-medium text-white">
                {feature.breakdown.title}
              </h1>
              <p className="text-sm lg:text-base text-gray-400">
                {feature.description}
              </p>

              <div className="space-y-4">
                {feature.breakdown.points.map((point, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start group"
                  >
                    <div className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 rounded-full bg-[#1C1C28] flex items-center justify-center mr-3 group-hover:bg-[#2D2D3D] transition-colors duration-300">
                      <span className="text-white font-medium text-xs lg:text-sm">
                        {index + 1}
                      </span>
                    </div>
                    <div>
                      <p className="text-sm lg:text-base text-white leading-relaxed">
                        {point}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>

              <div className="mt-12 flex justify-center">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    setSelectedFeature(null)
                    setStep(2)
                  }}
                  className={continueButtonClasses}
                >
                  Continue to Setup
                </motion.button>
              </div>
            </motion.div>
          </div>

          {/* Right Side - Live Demo */}
          <div className="w-full lg:w-3/5 p-4 lg:p-8 flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="relative w-full h-full flex items-center justify-center"
            >
              {renderLiveDemo(feature)}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  )

  const renderLiveDemo = (feature: Feature) => {
    const imageClasses =
      "w-full h-auto max-h-[calc(100vh-300px)] object-contain rounded-lg"

    switch (feature.breakdown.demo.type) {
      case "headlines":
        return (
          <div className="w-full space-y-4 p-4 lg:p-6">
            {feature.breakdown.demo.headlines?.map((headline, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 + index * 0.1 }}
                className="bg-[#0A0A0A] rounded-lg p-3 lg:p-4 border border-[#2A2A2A]"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs text-gray-500">{headline.time}</span>
                  {headline.hasAI && (
                    <div className="flex items-center">
                      <span className="w-2 h-2 rounded-full bg-zinc-700 mr-1" />
                      <span className="text-xs text-gray-500">
                        AI highlights
                      </span>
                    </div>
                  )}
                </div>
                <p className="text-xs lg:text-sm text-gray-300">
                  {headline.text}
                </p>
              </motion.div>
            ))}
          </div>
        )

      case "calendar":
        return (
          <div className="w-full">
            <div className="flex space-x-4 mb-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setActiveTab("calendar")}
                className={`px-3 lg:px-4 py-2 flex items-center space-x-2 text-sm lg:text-base ${
                  activeTab === "calendar"
                    ? "text-white"
                    : "text-gray-400 hover:text-white"
                }`}
              >
                <span className="text-lg">📅</span>
                <span>Economic Calendar</span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setActiveTab("ai-analysis")}
                className={`px-3 lg:px-4 py-2 flex items-center space-x-2 text-sm lg:text-base ${
                  activeTab === "ai-analysis"
                    ? "text-white"
                    : "text-gray-400 hover:text-white"
                }`}
              >
                <span className="text-lg">🤖</span>
                <span>AI Analysis</span>
              </motion.button>
            </div>

            <div className="relative">
              <AnimatePresence mode="wait">
                {activeTab === "calendar" ? (
                  <motion.img
                    key="calendar"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    src={economicCalendar}
                    alt="Economic Calendar"
                    className={imageClasses}
                  />
                ) : (
                  <motion.img
                    key="ai-analysis"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    src={econCalendarAi}
                    alt="AI Analysis"
                    className={imageClasses}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>
        )

      case "central-bank":
        return (
          <img
            src={feature.breakdown.demo.centralBank?.image}
            alt="Central Bank Events"
            className={imageClasses}
          />
        )

      case "volatility":
        return (
          <img
            src={feature.breakdown.demo.volatility?.image}
            alt="Volatility Predictions"
            className={imageClasses}
          />
        )

      default:
        return null
    }
  }

  const renderFeatureShowcase = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
      className="max-w-4xl w-full bg-[#0A0A0A] rounded-xl p-8 shadow-2xl border border-[#1E1E1E]"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-center mb-6"
      >
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "easeOut" }}
          className="relative"
        >
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              textShadow: [
                "0 0 0px rgba(255,255,255,0)",
                "0 0 20px rgba(255,255,255,0.5)",
                "0 0 0px rgba(255,255,255,0)",
              ],
            }}
            transition={{
              opacity: { duration: 0.5 },
              textShadow: {
                duration: 1.5,
                times: [0, 0.5, 1],
                ease: "easeInOut",
                delay: 0.5,
              },
            }}
            className="text-5xl font-light text-white mb-2"
          >
            Welcome to MRKT
          </motion.h1>
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.7, delay: 0.3 }}
            className="h-[1px] w-32 bg-gradient-to-r from-transparent via-zinc-500 to-transparent mx-auto mt-4"
          />
        </motion.div>
        <motion.p
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.7 }}
          className="text-white mt-2 text-lg"
        >
          Your AI-Powered Market Intelligence Platform
        </motion.p>
      </motion.div>
      <div className="grid grid-cols-2 gap-6">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.3 + index * 0.1,
              ease: "easeOut",
            }}
            onClick={() => setSelectedFeature(index)}
            className="bg-[#141414] p-6 rounded-lg hover:bg-[#1E1E1E] transition-all duration-300 border border-[#2A2A2A] cursor-pointer"
          >
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{
                duration: 0.5,
                delay: 0.5 + index * 0.1,
                ease: "easeOut",
              }}
              className="text-4xl mb-4"
            >
              {feature.icon}
            </motion.div>
            <motion.h2 className="text-xl font-semibold text-white mb-2">
              {feature.title}
            </motion.h2>
            <motion.p className="text-gray-400">{feature.description}</motion.p>
          </motion.div>
        ))}
      </div>

      <div className="mt-8 flex flex-col items-center space-y-4">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2 }}
          className="text-center text-gray-400"
        >
          Click on any feature to learn more
        </motion.p>

        <motion.button
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.4 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => setStep(2)}
          className="text-gray-400 hover:text-white px-6 py-2 rounded-lg border border-[#2A2A2A] hover:bg-[#1C1C28] transition-all duration-300"
        >
          Skip Features →
        </motion.button>
      </div>
    </motion.div>
  )

  const renderNameStep = () => (
    <div className="w-full">
      <BackButton onClick={() => setStep(1)} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="max-w-xl w-full bg-[#0A0A0A] rounded-xl p-8 shadow-2xl border border-[#1E1E1E]"
      >
        <h2 className="text-3xl font-medium text-white mb-8 text-center">
          What's your name?
        </h2>
        <input
          type="text"
          value={userProfile.name}
          onChange={(e) =>
            setUserProfile({ ...userProfile, name: e.target.value })
          }
          className="w-full bg-[#141414] text-white px-4 py-3 rounded-lg focus:ring-2 focus:ring-zinc-700 outline-none border border-[#2A2A2A]"
          placeholder="Enter your name"
        />
        <div className="mt-12 flex justify-center">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setStep(3)}
            disabled={!userProfile.name}
            className={continueButtonClasses}
          >
            Continue
          </motion.button>
        </div>
      </motion.div>
    </div>
  )

  const renderReferralStep = () => (
    <div className="w-full">
      <BackButton onClick={() => setStep(2)} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="max-w-xl w-full bg-[#0A0A0A] rounded-xl p-8 shadow-2xl border border-[#1E1E1E]"
      >
        <h2 className="text-3xl font-medium text-white mb-8 text-center">
          How did you hear about us?
        </h2>
        <div className="grid grid-cols-2 gap-6">
          {referralOptions.map((option) => (
            <motion.button
              key={option.id}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => {
                setUserProfile({
                  ...userProfile,
                  referralSource: option.id,
                })
              }}
              className={`p-2 rounded-lg flex flex-col items-center justify-center space-y-2 border-2 transition-all duration-200 ${
                userProfile.referralSource === option.id
                  ? "bg-[#2A2A2A] text-white border-[#4A4A4A]"
                  : "bg-[#141414] text-gray-300 hover:bg-[#1E1E1E] border-[#2A2A2A]"
              }`}
            >
              <span className="text-3xl">{option.icon}</span>
              <span className="text-sm font-medium text-center">
                {option.label}
              </span>
            </motion.button>
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setStep(4)}
            disabled={!userProfile.referralSource}
            className={continueButtonClasses}
          >
            Continue
          </motion.button>
        </div>
      </motion.div>
    </div>
  )

  const renderTradingExperienceStep = () => (
    <div className="w-full">
      <BackButton onClick={() => setStep(3)} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="max-w-xl w-full bg-[#0A0A0A] rounded-xl p-8 shadow-2xl border border-[#1E1E1E]"
      >
        <h2 className="text-3xl font-medium text-white mb-8 text-center">
          Your Trading Experience
        </h2>
        <div className="grid grid-cols-1 gap-6">
          {["beginner", "intermediate", "advanced"].map((level) => (
            <motion.button
              key={level}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => {
                setUserProfile({
                  ...userProfile,
                  tradingExperience: level as UserProfile["tradingExperience"],
                })
              }}
              className={`p-8 rounded-lg flex items-center justify-center border-2 transition-all duration-200 ${
                userProfile.tradingExperience === level
                  ? "bg-[#2A2A2A] text-white border-[#4A4A4A]"
                  : "bg-[#141414] text-gray-300 hover:bg-[#1E1E1E] border-[#2A2A2A]"
              }`}
            >
              <span className="text-xl font-medium capitalize">{level}</span>
            </motion.button>
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setStep(5)}
            disabled={!userProfile.tradingExperience}
            className={continueButtonClasses}
          >
            Continue
          </motion.button>
        </div>
      </motion.div>
    </div>
  )

  const tradingInstrumentIcons = {
    forex: "💱",
    equities: "📈",
    commodities: "🛢️",
    crypto: "₿",
  } as const

  const renderTradingInstrumentsStep = () => (
    <div className="w-full">
      <BackButton onClick={() => setStep(4)} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="max-w-xl w-full bg-[#0A0A0A] rounded-xl p-8 shadow-2xl border border-[#1E1E1E]"
      >
        <h2 className="text-3xl font-medium text-white mb-8 text-center">
          What do you trade?
        </h2>
        <div className="grid grid-cols-2 gap-6">
          {["forex", "equities", "commodities", "crypto"].map((instrument) => (
            <motion.button
              key={instrument}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => {
                const instruments = userProfile.tradingInstruments.includes(
                  instrument as any,
                )
                  ? userProfile.tradingInstruments.filter(
                      (i) => i !== instrument,
                    )
                  : [...userProfile.tradingInstruments, instrument]
                setUserProfile({
                  ...userProfile,
                  tradingInstruments: instruments as any,
                })
              }}
              className={`p-8 rounded-lg flex flex-col items-center justify-center space-y-3 border-2 transition-all duration-200 ${
                userProfile.tradingInstruments.includes(instrument as any)
                  ? "bg-[#2A2A2A] text-white border-[#4A4A4A]"
                  : "bg-[#141414] text-gray-300 hover:bg-[#1E1E1E] border-[#2A2A2A]"
              }`}
            >
              <span className="text-4xl">
                {
                  tradingInstrumentIcons[
                    instrument as keyof typeof tradingInstrumentIcons
                  ]
                }
              </span>
              <span className="text-xl font-medium capitalize">
                {instrument}
              </span>
            </motion.button>
          ))}
        </div>
        <div className="mt-8 flex justify-center">
          c
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setStep(6)}
            disabled={userProfile.tradingInstruments.length === 0}
            className={continueButtonClasses}
          >
            Continue
          </motion.button>
        </div>
      </motion.div>
    </div>
  )

  const renderFundamentalsStep = () => (
    <div className="w-full">
      <BackButton onClick={() => setStep(5)} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="max-w-xl w-full bg-[#0A0A0A] rounded-xl p-8 shadow-2xl border border-[#1E1E1E]"
      >
        <h2 className="text-3xl font-medium text-white mb-8 text-center">
          Fundamentals & Macro Economics Experience
        </h2>
        <div className="grid grid-cols-1 gap-6">
          {["beginner", "intermediate", "advanced"].map((level) => (
            <motion.button
              key={level}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => {
                setUserProfile({
                  ...userProfile,
                  fundamentalsExperience:
                    level as UserProfile["fundamentalsExperience"],
                })
              }}
              className={`p-6 rounded-lg flex items-center justify-center border-2 transition-all duration-200 ${
                userProfile.fundamentalsExperience === level
                  ? "bg-[#2A2A2A] text-white border-[#4A4A4A]"
                  : "bg-[#141414] text-gray-300 hover:bg-[#1E1E1E] border-[#2A2A2A]"
              }`}
            >
              <span className="text-xl font-medium capitalize">{level}</span>
            </motion.button>
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setStep(7)}
            disabled={!userProfile.fundamentalsExperience}
            className={continueButtonClasses}
          >
            Continue
          </motion.button>
        </div>
      </motion.div>
    </div>
  )

  const renderSummaryStep = () => (
    <div className="w-full">
      <BackButton onClick={() => setStep(6)} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="max-w-xl w-full bg-[#0A0A0A] rounded-xl p-6 shadow-2xl border border-[#1E1E1E]"
      >
        <h2 className="text-3xl font-medium text-white mb-6 text-center">
          Your Profile Summary
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-[#141414] p-4 rounded-lg border border-[#2A2A2A] col-span-2">
            <h3 className="text-lg font-medium text-white mb-2">
              Personal Info
            </h3>
            <p className="text-base text-gray-400">
              Name: <span className="text-white">{userProfile.name}</span>
            </p>
          </div>

          <div className="bg-[#141414] p-4 rounded-lg border border-[#2A2A2A] col-span-2 lg:col-span-1">
            <h3 className="text-lg font-medium text-white mb-2">Experience</h3>
            <p className="text-base text-gray-400">
              Trading:{" "}
              <span className="text-white capitalize">
                {userProfile.tradingExperience}
              </span>
            </p>
            <p className="text-base text-gray-400">
              Fundamentals:{" "}
              <span className="text-white capitalize">
                {userProfile.fundamentalsExperience}
              </span>
            </p>
          </div>

          <div className="bg-[#141414] p-4 rounded-lg border border-[#2A2A2A] col-span-2 lg:col-span-1">
            <h3 className="text-lg font-medium text-white mb-2">Found via</h3>
            <p className="text-base text-gray-400">
              <span className="text-white">
                {
                  referralOptions.find(
                    (opt) => opt.id === userProfile.referralSource,
                  )?.label
                }
              </span>
            </p>
          </div>

          <div className="bg-[#141414] p-4 rounded-lg border border-[#2A2A2A] col-span-2">
            <h3 className="text-lg font-medium text-white mb-2">
              Trading Instruments
            </h3>
            <div className="flex flex-wrap gap-2">
              {userProfile.tradingInstruments.map((instrument) => (
                <span
                  key={instrument}
                  className="px-3 py-1.5 bg-[#1C1C28] rounded-full text-white text-sm capitalize flex items-center gap-1.5"
                >
                  {tradingInstrumentIcons[instrument]} {instrument}
                </span>
              ))}
            </div>
          </div>

          <div className="col-span-2 mt-6">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={completeUserOnboarding}
              className={continueButtonClasses}
            >
              Complete Setup
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  )

  const getCurrentStep = () => {
    if (selectedFeature !== null) {
      return renderFeatureBreakdown(features[selectedFeature])
    }

    switch (step) {
      case 1:
        return renderFeatureShowcase()
      case 2:
        return renderNameStep()
      case 3:
        return renderReferralStep()
      case 4:
        return renderTradingExperienceStep()
      case 5:
        return renderTradingInstrumentsStep()
      case 6:
        return renderFundamentalsStep()
      case 7:
        return renderSummaryStep()
      default:
        return null
    }
  }

  return (
    <div className="min-h-screen bg-black relative flex items-center justify-center p-4">
      <ProgressBar step={step} />
      <div className="mt-20">
        <AnimatePresence mode="wait">{getCurrentStep()}</AnimatePresence>
      </div>
    </div>
  )
}
