import { CentralBankEvent } from "./CentralBankEvents"
import { DNA } from "react-loader-spinner"
import { formatEventDate } from "../../utilities"
import { useState, useEffect } from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  HelpCircle,
  Minus,
  TrendingDown,
  TrendingUp,
  X,
} from "lucide-react"
import { Badge } from "@/components/ui/badge"
import {
  HeadlineBadge,
  HeadlinePriority,
} from "../MarketHeadlines/HeadlineBadge"
import { FedSpeakerInfo } from "./utils"

interface EventAnalysisDialogChipProps {
  label: string
  type: string
}

export const EventAnalysisDialogChip: React.FC<
  EventAnalysisDialogChipProps
> = ({ label, type }) => {
  const baseClasses =
    "inline-flex items-center rounded-full px-4 py-2 text-sm font-semibold shadow-md transition-all duration-200 ease-in-out"

  switch (type) {
    case "Bullish":
      return (
        <span
          className={`${baseClasses} bg-green-900 text-green-100 hover:bg-green-800 ml-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
            />
          </svg>
          {label}
        </span>
      )
    case "Bearish":
      return (
        <span
          className={`${baseClasses} bg-red-900 text-red-100 hover:bg-red-800 ml-2`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
          {label}
        </span>
      )
    default:
      return (
        <span
          className={`${baseClasses} bg-gray-800 text-gray-200 hover:bg-gray-700`}
        >
          {label}
        </span>
      )
  }
}

interface EventDialogContentProps {
  event: CentralBankEvent | null
  eventInfo: FedSpeakerInfo | null
  isLoading: boolean
  onClose: () => void
  isPastEvent: boolean
}

//TODO : Event Summary needs to be refactored to use different component (isPastEvent = true)
export const EventDialogContent = ({
  event,
  eventInfo,
  isLoading,
  onClose,
  isPastEvent,
}: EventDialogContentProps) => {
  const [activeTab, setActiveTab] = useState("intraday")

  if (!event) return null

  console.log("eventInfo", eventInfo)

  const getImpactIcon = (impact: string) => {
    switch (impact.toLowerCase()) {
      case "bearish":
        return (
          <ArrowDown className="w-4 h-4 text-red-500" aria-label="Bearish" />
        )
      case "bullish":
        return (
          <ArrowUp className="w-4 h-4 text-green-500" aria-label="Bullish" />
        )
      case "slightly bearish":
        return (
          <TrendingDown
            className="w-4 h-4 text-red-400"
            aria-label="Slightly Bearish"
          />
        )
      case "slightly bullish":
        return (
          <TrendingUp
            className="w-4 h-4 text-green-400"
            aria-label="Slightly Bullish"
          />
        )
      case "neutral":
        return (
          <Minus className="w-4 h-4 text-yellow-500" aria-label="Neutral" />
        )
      default:
        return (
          <ArrowRight
            className="w-4 h-4 text-yellow-500"
            aria-label="Unknown impact"
          />
        )
    }
  }

  const renderAssetAnalysis = (assets: any) => (
    <div className="space-y-2">
      {assets.map((asset: any) => (
        <div key={asset.asset} className="flex items-center justify-between">
          <Badge
            variant="outline"
            className="bg-zinc-900 text-white border-zinc-800 px-4 py-2 text-sm font-medium"
          >
            {asset.asset}
          </Badge>
          <div className="flex items-center space-x-2">
            {getImpactIcon(asset.impact)}
            <span className="text-md text-white">{asset.impact}</span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="w-4 h-4 text-white" />
                </TooltipTrigger>
                <TooltipContent className="bg-gray-800 border border-gray-700 text-white">
                  <p className="max-w-[300px] text-md">{asset.rationale}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      ))}
    </div>
  )

  const LoadingDialog = () => {
    const [loadingText, setLoadingText] = useState(
      "Analyzing historical patterns...",
    )
    const [cycleComplete, setCycleComplete] = useState(false)

    useEffect(() => {
      const messages = [
        "Analyzing historical patterns...",
        "Processing market correlations...",
        "Calculating potential impacts...",
        "Finalizing MRKT AI insights...",
      ]
      let currentIndex = 0

      if (!cycleComplete) {
        const intervalId = setInterval(() => {
          currentIndex = currentIndex + 1

          if (currentIndex < messages.length) {
            setLoadingText(messages[currentIndex])
          } else {
            setLoadingText(messages[messages.length - 1])
            setCycleComplete(true)
            clearInterval(intervalId)
          }
        }, 2000)

        return () => clearInterval(intervalId)
      }
    }, [cycleComplete])

    return (
      <div className="h-full backdrop-blur-xl bg-black/80 border-l border-[#333]">
        <div className="p-6 h-full overflow-y-auto">
          <div className="flex flex-row justify-between mb-1">
            <div className="flex flex-row items-center">
              <h1 className="text-xl font-medium text-white">{event.name}</h1>
            </div>

            <button
              type="button"
              onClick={onClose}
              className="rounded-md text-white hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <h2 className="text-base/7 font-medium text-gray-400 mb-6">
            {formatEventDate(event.dateUtc)}
          </h2>

          <div className="flex flex-col items-center justify-center py-8">
            <h2 className="text-xl font-medium text-white mb-4">
              {loadingText}
            </h2>
            <DNA
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{ color: "orange" }}
              wrapperClass="dna-wrapper"
            />
          </div>

          <div className="space-y-6">
            <div>
              <div className="h-6 bg-zinc-800/50 rounded w-40 mb-3"></div>
              <div className="space-y-2">
                <div className="h-4 bg-zinc-800/50 rounded w-full"></div>
                <div className="h-4 bg-zinc-800/50 rounded w-3/4"></div>
              </div>
            </div>

            <div>
              <div className="h-6 bg-zinc-800/50 rounded w-40 mb-3"></div>
              <div className="space-y-2">
                <div className="h-4 bg-zinc-800/50 rounded w-full"></div>
                <div className="h-4 bg-zinc-800/50 rounded w-5/6"></div>
                <div className="h-4 bg-zinc-800/50 rounded w-4/5"></div>
              </div>
            </div>

            <div>
              <div className="h-6 bg-zinc-800/50 rounded w-40 mb-3"></div>
              <div className="space-y-2">
                <div className="h-4 bg-zinc-800/50 rounded w-full"></div>
                <div className="h-4 bg-zinc-800/50 rounded w-3/4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <LoadingDialog />
  }

  return (
    <div className="h-full backdrop-blur-2xl bg-black/80 border-l border-[#333]">
      <div className="p-6 h-full overflow-y-auto ">
        <div className="flex flex-row justify-between mb-1">
          <div className="flex flex-row items-center">
            <h1 className="text-xl font-medium text-white">{event.name}</h1>
          </div>

          <button
            type="button"
            onClick={onClose}
            className="rounded-md text-white hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <h2 className="text-base/7 font-medium text-gray-400">
          {formatEventDate(event.dateUtc)}
        </h2>
        <div className="flex items-center mt-2">
          {HeadlineBadge(event.volatility as HeadlinePriority)}
        </div>
        <h3 className="font-bold text-lg text-white pt-5">
          {isPastEvent ? "Event Summary" : "MRKT AI Analysis"}
        </h3>
        <p className="text-[16px] text-gray-400 pt-3">
          {eventInfo?.analysis.analysis}
        </p>

        <h3 className="font-bold text-lg text-white pt-5">Potential Tone</h3>
        <div className="flex flex-col text-[16px] text-gray-400 pt-3">
          <div>
            Tone:
            <span className="ml-2 text-purple-400">
              {eventInfo?.analysis.overall_tone}
            </span>
          </div>
          <div>
            Strength:
            <span className="ml-2 text-purple-400">
              {eventInfo?.analysis.tone_strength}
            </span>
          </div>
        </div>

        <h3 className="font-bold text-lg text-white pt-5">
          Market Expectations
        </h3>
        <p className="text-[16px] text-gray-400 pt-3">
          {eventInfo?.analysis.market_expectations}
        </p>

        <h3 className="font-bold text-lg text-white pt-5">
          Pricing In Assessment
        </h3>
        <p className="text-[16px] text-gray-400 pt-3">
          {eventInfo?.analysis.pricing_in_assessment}
        </p>

        <h3 className="font-bold text-lg text-white pt-5">Key Factors</h3>
        <ul className="text-[16px] text-gray-400 pt-3">
          {eventInfo?.analysis.key_factors.map((factor) => (
            <li key={factor}>{factor}</li>
          ))}
        </ul>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-2 mt-4 bg-gray-900 text-white">
            <TabsTrigger
              value="intraday"
              className="data-[state=active]:bg-gray-600/40 data-[state=active]:text-white rounded-md transition-all"
            >
              Intraday Analysis
            </TabsTrigger>
            <TabsTrigger
              value="swing"
              className="data-[state=active]:bg-gray-600/40 data-[state=active]:text-white rounded-md transition-all"
            >
              Swing Trade Analysis
            </TabsTrigger>
          </TabsList>
          <TabsContent value="intraday" className="space-y-4 mt-4">
            <p className="text-md text-white">
              {eventInfo?.analysis.intraday_analysis.overview}
            </p>
            {renderAssetAnalysis(eventInfo?.analysis.intraday_analysis.assets)}
          </TabsContent>
          <TabsContent value="swing" className="space-y-4 mt-4">
            <p className="text-md text-white">
              {eventInfo?.analysis.swing_trade_analysis.overview}
            </p>
            {renderAssetAnalysis(
              eventInfo?.analysis.swing_trade_analysis.assets,
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}
