import { ChangeEvent, useId } from "react"
import { Checkbox } from "./Checkbox"

type MultiSelectCheckboxProps = {
  label: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  className?: string
}

export const MultiSelectCheckbox = ({
  label,
  onChange,
  checked,
  className,
}: MultiSelectCheckboxProps) => {
  const id = useId()

  return (
    <div className={className}>
      <label
        htmlFor={id}
        className=" flex w-full cursor-pointer gap-2 py-3 px-2"
      >
        <Checkbox id={id} onChange={onChange} checked={checked} />
        {label}
      </label>
    </div>
  )
}
