export const HeadlineBadge = (badge: HeadlinePriority) => {
  switch (badge) {
    case HeadlinePriority.HIGH:
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-red-400/20 px-2 py-1 text-sm font-normal text-red-400 w-20">
          High
        </span>
      )
    case HeadlinePriority.MEDIUM:
      return (
        <span className="inline-flex items-center justify-center rounded-md px-2 py-1 text-sm font-normal bg-yellow-400/10 text-yellow-400 w-20">
          Medium
        </span>
      )
    case HeadlinePriority.LOW:
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-green-500/10 px-2 py-1 text-sm font-normal text-green-400 w-20">
          Low
        </span>
      )
    case HeadlinePriority.NONE:
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-gray-500/10 px-2 py-1 text-sm font-normal text-gray-400 w-20">
          None
        </span>
      )
  }
}

export enum HeadlinePriority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  NONE = "NONE",
}
