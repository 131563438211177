import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { LoadingScreen, useUser } from "@/providers"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Switch } from "@/components/ui/switch"
import { ArrowRight, Check } from "lucide-react"
import { useState } from "react"
import clsx from "clsx"
import { cn } from "@/lib/utils"

export const Route = createFileRoute("/checkout")({
  validateSearch: (search: Record<string, unknown>) => {
    const plan = search.plan as string | undefined
    if (plan && !["monthly", "annual"].includes(plan)) {
      return { plan: undefined }
    }
    return { plan: plan as "monthly" | "annual" | undefined }
  },
  component: () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
    const { plan } = Route.useSearch()
    const navigate = useNavigate()
    const { isSubscribed, isLoading: isUserLoading } = useUser()

    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: { returnTo: "/checkout", plan },
        })
      }
      if (isAuthenticated && isSubscribed) {
        navigate({ to: "/dashboard" })
      }
    }, [isLoading, isAuthenticated, plan, isSubscribed])

    if (isLoading || isUserLoading) {
      return <LoadingScreen />
    }

    if (!plan && !isSubscribed && isAuthenticated) {
      return <PricingSection />
    }

    return <CheckoutPage />
  },
})

const pricingOptions = [
  {
    name: (
      <div className="flex items-center gap-2 min-h-[44px]">
        <span className="text-white text-lg font-bold">PRO</span>
      </div>
    ),
    price: "$49.99",
    yearlyPrice: "$499.99",
    description:
      "Access real-time market data and AI-powered insights to make smarter financial decisions.",
    features: [
      "Real-Time Market Headlines",
      "AI-Powered Financial Insights",
      "Volume & Volatility Alerts",
      "AI-Powered Economic Calendar",
      "Real-Time Market Data Access",
    ],
  },
  {
    name: (
      <div className="flex items-center gap-3 min-h-[44px]">
        <span className="text-white text-lg font-bold">PRO</span>
        <span className="text-white font-semibold bg-green-500/70 px-2 py-2 rounded-lg">
          Capital Hungry Members Discount
        </span>
      </div>
    ),
    price: "$24.99",
    yearlyPrice: "$249.99",
    description:
      "Get the same powerful financial intelligence tools at a special rate for Capital Hungry members.",
    features: [
      "Real-Time Market Headlines",
      "AI-Powered Financial Insights",
      "Volume & Volatility Alerts",
      "AI-Powered Economic Calendar",
      "Real-Time Market Data Access",
    ],
  },
]

function PricingSection() {
  const [isYearly, setIsYearly] = useState(false)

  return (
    <section id="pricing" className="min-h-screen bg-black">
      <div className="container mx-auto max-w-5xl py-10">
        <div className="mx-auto space-y-4 py-6 text-center">
          <h4 className="mx-auto mb-2 max-w-3xl text-balance text-[42px] font-medium tracking-tighter text-white">
            AI-Powered Market Intelligence
          </h4>
        </div>
        <p className="mt-6 text-center text-xl leading-8 text-white">
          Access{" "}
          <strong className="text-green-500">AI-driven market analysis</strong>{" "}
          that predicts volatility, forecasts market movements, and alerts you
          to critical economic events. Our AI processes millions of data points
          to give you an edge in the markets.
        </p>
        <div className="mt-5 flex items-center justify-center space-x-2">
          <span
            className={clsx("font-bold", {
              "text-white": !isYearly,
              "text-gray-500": isYearly,
            })}
          >
            Monthly
          </span>
          <Switch
            checked={isYearly}
            onCheckedChange={setIsYearly}
            className={cn(
              "data-[state=checked]:bg-green-500",
              "data-[state=unchecked]:bg-zinc-700",
            )}
          />
          <span
            className={clsx("font-bold", {
              "text-white": isYearly,
              "text-gray-500": !isYearly,
            })}
          >
            Yearly
          </span>
        </div>
        <div className="mx-auto grid gap-6 px-10 py-8 lg:grid-cols-2">
          {pricingOptions.map((option, index) => (
            <Card
              key={index}
              className={clsx(
                "flex flex-col border border-zinc-800/40 bg-zinc-900/40 backdrop-blur-sm p-4",
              )}
            >
              <CardHeader>
                <CardTitle className="text-white">{option.name}</CardTitle>
                <p className="text-white">{option.description}</p>
              </CardHeader>
              <CardContent className="flex-grow">
                <div className="text-3xl font-bold text-white">
                  {isYearly ? option.yearlyPrice : option.price}
                  <span className="text-sm font-medium text-gray-400">
                    {isYearly ? "USD/year" : "USD/month"}
                  </span>
                </div>
                <ul className="mt-4 space-y-2">
                  {option.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className="flex items-center text-gray-300"
                    >
                      <Check className="mr-2 h-4 w-4 text-white" />
                      <span className="text-white">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="flex justify-center mt-5">
                <Button
                  className={clsx(
                    "w-full bg-gray-600/50 hover:bg-gray-700/50 text-white text-md font-semibold",
                  )}
                  onClick={() => {
                    const isCapitalHungry =
                      option.name.props.children[1] !== undefined
                    const route = isCapitalHungry
                      ? "/capital-hungry"
                      : "/checkout"
                    const planType = isYearly ? "annual" : "monthly"
                    window.location.href = `${route}?plan=${planType}`
                  }}
                >
                  Choose Plan
                  <ArrowRight className="ml-2 h-4 w-4 text-white" />
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
        <div className="text-center mt-4 pb-8">
          <a
            href="https://mrktedge.ai"
            className="text-zinc-400 hover:text-zinc-200 text-md underline"
          >
            Return to Website
          </a>
        </div>
      </div>
    </section>
  )
}

function CheckoutPage() {
  const { plan } = Route.useSearch()
  const { user, getAccessTokenSilently } = useAuth0()
  const { isSubscribed, isLoading: isUserLoading } = useUser()

  const { data: checkoutSession, isLoading: isCheckoutSessionLoading } =
    useQuery({
      queryKey: ["checkout-session", plan],
      queryFn: async () => {
        if (!user?.email) return null

        const token = await getAccessTokenSilently()
        const response = await fetch(
          `${import.meta.env.VITE_MRKT_SERVER}/stripe/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: user.email,
              plan,
              auth0Id: user.sub,
            }),
          },
        )

        if (!response.ok) {
          throw new Error("Failed to create checkout session")
        }

        return response.json()
      },
      enabled: !!user?.email && !isSubscribed,
    })

  useEffect(() => {
    if (checkoutSession?.url) {
      window.location.href = checkoutSession.url
    }
  }, [checkoutSession])

  if (isCheckoutSessionLoading) {
    return <LoadingScreen />
  }

  return <LoadingScreen />
}
