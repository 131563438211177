import { useAuth0 } from "@auth0/auth0-react"
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid"

export const FloatingButton = () => {
  const { isAuthenticated } = useAuth0()
  if (!isAuthenticated) return null
  return (
    <button
      className="fixed bottom-6 right-6 bg-purple-500 backdrop-blur-md text-white border-2 border-purple-800 rounded-full shadow-lg 
                 group flex items-center justify-start gap-2
                 w-12 h-12 hover:w-[170px] overflow-hidden font-bold"
      style={{ transition: "width 300ms ease-in-out" }}
      onClick={() => {
        window.open("https://mrkt.featurebase.app/")
      }}
    >
      <ChatBubbleLeftRightIcon className="h-6 w-6 ml-3 flex-shrink-0" />
      <span
        className="absolute left-12 opacity-0 group-hover:opacity-100 transition-opacity duration-150 whitespace-nowrap"
        style={{ transitionDelay: "300ms" }}
      >
        Send Feedback
      </span>
    </button>
  )
}
