import { HeadlineAnalysisChipEnums } from "../enums/HeadlineAnalysisChipEnums"
import { HeadlineAnalysisDialogChip } from "./HeadlineAnalysisDialogChip"
import { DNA } from "react-loader-spinner"
import { HeadlineAnalysis } from "../Data/HeadlineAnalysisData"
import { X } from "lucide-react"

interface AnalysisDialogContentProps {
  headlineAnalysisData?: HeadlineAnalysis
  headlineTitle?: string
  headlineTime: string
  isLoading: boolean
  onClose: () => void
}

export type Asset = {
  currency: string
  impact: string
}

export const AnalysisDialogContent = ({
  headlineAnalysisData,
  headlineTime,
  headlineTitle,
  isLoading,
  onClose,
}: AnalysisDialogContentProps) => {
  const getHeadlineAnalysisChipType = (impact: string) => {
    if (impact === "Bullish") {
      return HeadlineAnalysisChipEnums.Bullish
    } else if (impact === "Bearish") {
      return HeadlineAnalysisChipEnums.Bearish
    } else {
      return HeadlineAnalysisChipEnums.General
    }
  }

  return (
    <div className="bg-gray-800/50 rounded-lg border border-gray-700/50 p-4">
      {!isLoading ? (
        <>
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-medium text-lg text-white">MRKT AI Analysis</h3>
            <button
              onClick={onClose}
              className="p-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200"
            >
              <X className="w-4 h-4 text-gray-400" />
            </button>
          </div>
          <p className="text-md text-gray-200 mb-4">
            {headlineAnalysisData?.summary}
          </p>
          <div className="flex flex-wrap gap-2">
            {headlineAnalysisData?.assets.map((asset: Asset, index) => (
              <HeadlineAnalysisDialogChip
                key={index}
                label={asset.currency}
                type={getHeadlineAnalysisChipType(asset.impact)}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center py-4">
          <DNA
            visible={true}
            height="60"
            width="60"
            ariaLabel="dna-loading"
            wrapperStyle={{ color: "orange" }}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}
    </div>
  )
}
