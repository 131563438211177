export enum HeadlineAnalysisChipEnums {
  "General",
  "Bullish",
  "Bearish",
}

export const headlinesKeywords = [
  "COVID-19",
  "CORONAVIRUS",
  "VACCINE",
  "PANDEMIC",
  "US",
  "USA",
  "U.S",
  "USD",
  "TRUMP",
  "FED",
  "BAILEY",
  "POWELL",
  "BIDEN",
  "BARKIN",
  "MNUCHIN",
  "DEBT",
  "OIL",
  "CRUDE",
  "COAL",
  "DOLLAR",
  "POUND",
  "GOLD",
  "SILVER",
  "METALS",
  "LIBERAL",
  "U.K",
  "UK",
  "GBP",
  "STERLING",
  "BOE",
  "BREXIT",
  "BORIS",
  "JOHNSON",
  "SAUNDERS",
  "ENGLAND",
  "LAGARDE",
  "BRITISH",
  "HALDANE",
  "HANCOCK",
  "JAPAN",
  "BOJ",
  "TOKYO",
  "KURODA",
  "YEN",
  "JPY",
  "SHANGHAI",
  "CHINA",
  "PBOC",
  "YUAN",
  "CBANK",
  "TAIWAN",
  "CANADA",
  "CAD",
  "CANADIAN",
  "TRUDEAU",
  "JUSTIN",
  "BOC",
  "AUD",
  "RBA",
  "RBNZ",
  "NZD",
  "NZ",
  "ZEALAND",
  "AUSTRALIA",
  "STOCKS",
  "INDICE",
  "BOND",
  "FINANCE",
  "ECONOMIC",
  "MINISTER",
  "G20",
  "PMI",
  "EXPORTS",
  "INFLATION",
  "CPI",
  "PPI",
  "PCE",
  "CORE",
  "G7",
  "CENBANK",
  "EU",
  "ECB",
  "EURO",
  "MERKEL",
  "EUROPE",
  "UKRAINE",
  "GERMANY",
  "MACRON",
  "ITALY",
  "SWISS",
  "EUROPEAN",
  "RUSSIA",
  "ISREAL",
  "IRAN",
  "SAUDI",
  "INDIA",
  "RBI",
  "RUPEE",
  "FRENCH",
  "FRANCE",
  "ROUBLE",
  "GERMAN",
  "BITCOIN",
  "BTC",
  "RIPPLE",
  "XRP",
  "GAZA",
  "ETHEREUM",
  "ETH",
  "CRYPTO",
  "CRYPTOCURRENCY",
  "KATAOKA",
  "MODERNA",
  "ASTRAZENECA",
  "JOHNSON & JOHNSON",
  "BINANCE",
  "PFIZER",
  "KRAKEN",
  "COINBASE",
  "ELON",
  "DOGECOIN",
  "BLOCKCHAIN",
  "LEBANON",
  "LEBANESE",
  "WAR",
  "RUSSIA-UKRAINE",
  "UKRAINE-RUSSIA",
  "PUTIN",
  "ZELLENSKIY",
  "ZELLENSKI",
  "MISSILE",
  "STRIKE",
  "RATE CUTS",
  "RATE",
  "CUTS",
  "CENTRAL BANK",
  "BANK OF ENGLAND",
  "BANK OF JAPAN",
  "BANK OF CANADA",
  "BANK OF AUSTRALIA",
  "BANK OF NEW ZEALAND",
  "BANK OF RUSSIA",
  "BANK OF INDIA",
  "REUTERS",
  "RETAIL",
  "SALES",
  "STOCK",
  "MODI",
  "KREMLIN",
  "HEZBOLLAH",
  "HAMAS",
  "PALESTINIANS",
  "PALESTINIAN",
  "FOREX",
  "ROCKETS",
  "ROCKET",
  "TEL AVIV",
  "FEDERAL",
  "RESERVE",
  "RESERVES",
  "RESERVE BANK",
  "VOLATILITY",
  "FX",
  "KAMALA",
  "HARRIS",
  "GDP",
  "LABOR",
  "UNEMPLOYMENT",
  "LABOUR",
  "PRODUCTION",
  "JEROME",
  "BERNANKE",
  "PAUL",
  "VOLCKER",
  "GREENSPAN",
  "KASHKARI",
  "BOWMAN",
  "BOSTIC",
  "MUSALEM",
  "KUGLER",
  "JEFFERSON",
  "LOGAN",
  "GOOLSBEE",
  "DIMON",
  "WILLIAMS",
  "FOMC",
  "MINUTES",
  "COLLINS",
  "DALY",
  "CONSUMER",
  "CONFIDENCE",
  "ELDERSON",
  "MANUFACTURING",
  // "INDEX",
  "NY",
  "UEDA",
  "APPLE",
  "GOOGLE",
  "AMAZON",
  "MICROSOFT",
  "FACEBOOK",
  "META",
  "ALPHABET",
  "YOUTUBE",
  "ELON",
  "MUSK",
  "TESLA",
  "SPACE",
  "STARLINK",
  "YEMEN",
  "CDC",
  "UN",
  "ELECTIONS",
  "JAPAN",
  "JAPANESE",
  "2024",
  "2025",
  "ISRAEL",
  "PALESTINE",
  "NETANYAHU",
  "LAGARDE",
  "PAYROLLS",
  "50",
  "25",
  "BPS",
  "EMPLOYMENT",
  "EU'S",
  "GENTILONI",
  "GEOPOLITICAL",
  "MIDDLE",
  "EAST",
  "WEST",
  "BEIRUT",
  "TREASURY",
  "CHINA'S",
  "JAPAN'S",
  "GERMANY'S",
  "FRANCE'S",
  "INDIA'S",
  "CANADA'S",
  "10-YEAR",
  "30-YEAR",
  "BILL",
  "TREASURY",
  "YIELD",
  "TREASURIES",
  "NEWSCAST",
  "WSJ",
  "STREET",
  "BLOOMBERG",
  "BULLS",
  "BEARS",
  "NFP",
]
