export enum OverviewChartTypes {
  SlightlyBullish = "Slightly Bullish",
  Bullish = "Bullish",
  VeryBullish = "Very Bullish",
  SlightlyBearish = "Slightly Bearish",
  Bearish = "Bearish",
  VeryBearish = "Very Bearish",
}

export function getOverviewGradient(type: OverviewChartTypes) {
  if (
    type === OverviewChartTypes.SlightlyBullish ||
    type === OverviewChartTypes.Bullish ||
    type === OverviewChartTypes.VeryBullish
  ) {
    return "h-2 rounded-full bg-gradient-to-r from-green-400 to-green-700"
  } else {
    return "h-2 rounded-full bg-gradient-to-r from-red-400 to-red-700"
  }
}

export function getOverviewValue(type: OverviewChartTypes) {
  if (
    type === OverviewChartTypes.SlightlyBullish ||
    type === OverviewChartTypes.SlightlyBearish
  ) {
    return "25%"
  } else if (
    type === OverviewChartTypes.Bullish ||
    type === OverviewChartTypes.Bearish
  ) {
    return "50%"
  } else if (
    type === OverviewChartTypes.VeryBullish ||
    type === OverviewChartTypes.VeryBearish
  ) {
    return "100%"
  } else {
    return "0%"
  }
}

export function getSentimentBackground(type: OverviewChartTypes) {
  if (
    type === OverviewChartTypes.SlightlyBullish ||
    type === OverviewChartTypes.Bullish ||
    type === OverviewChartTypes.VeryBullish
  ) {
    return "absolute top-0 right-0 w-40 h-40 bg-green-500 rounded-full filter blur-2xl opacity-40"
  } else {
    return "absolute top-0 right-0 w-40 h-40 bg-red-500 rounded-full filter blur-2xl opacity-40"
  }
}
