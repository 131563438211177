import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface MarketTooltipProps {
  name: string
  hours: string
  volume: "High" | "Medium" | "Low"
  children: React.ReactNode
}

const volumeColors = {
  High: "text-green-400",
  Medium: "text-yellow-400",
  Low: "text-red-400",
}

export const MarketTooltip = ({
  name,
  hours,
  volume,
  children,
}: MarketTooltipProps) => {
  const timeZone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2]

  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className="backdrop-blur-md bg-black/30 text-white p-2 rounded-lg shadow-lg border border-white/10 w-[250px]">
        <div className="space-y-1">
          <p className="font-extrabold text-md mb-2 text-purple-400">
            {name} Session
          </p>
          <div className="space-y-1 text-sm">
            <p className="flex justify-between">
              <span className="text-gray-300">Trading Hours:</span>
              <span className="font-medium">{hours}</span>
            </p>
            <p className="flex justify-between">
              <span className="text-gray-300">Volume:</span>
              <span className={`font-medium ${volumeColors[volume]}`}>
                {volume}
              </span>
            </p>
            <p className="flex justify-between">
              <span className="text-gray-300">Time Zone:</span>
              <span className="font-medium">{timeZone}</span>
            </p>
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  )
}
