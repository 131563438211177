export interface Headlines {
  _id: string
  date: string
  text: string
  uid: string
  __v: number
}
export interface FedSpeakerInfo {
  headlines: string[]
  analysis: {
    analysis: string
    overall_tone: string
    tone_strength: string
    market_expectations: string
    intraday_analysis: {
      overview: string
      assets: {
        asset: string
        impact: string
        confidence: string
        rationale: string
      }[]
      volatility_expectation: string
    }
    swing_trade_analysis: {
      overview: string
      assets: {
        asset: string
        impact: string
        confidence: string
        rationale: string
      }[]
      volatility_expectation: string
      trend_strength: string
    }
    key_factors: string[]
    potential_risks: string
    pricing_in_assessment: string
  }
}

export const fetchCentralBankEventInfo = async (
  eventName: string,
  centralBank: string,
  getAccessTokenSilently: () => Promise<string>,
): Promise<FedSpeakerInfo[]> => {
  try {
    let centralBankUrl = ""
    if (centralBank === "US") {
      centralBankUrl = "fed"
    } else if (centralBank === "JP") {
      centralBankUrl = "boj"
    } else {
      centralBankUrl = "boe"
    }

    const response = await fetch(
      `${import.meta.env.VITE_MRKT_SERVER}/central-banks/summarize/${centralBankUrl}?event=${encodeURIComponent(eventName)}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    )

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return data as FedSpeakerInfo[]
  } catch (error) {
    console.error("Error fetching Fed Speaker info:", error)
    throw error
  }
}

export const getEventAnalysis = async (
  eventName: string,
  centralBank: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const data = await fetchCentralBankEventInfo(
      eventName,
      centralBank,
      getAccessTokenSilently,
    )
    return data
  } catch (error) {
    console.error("Error in handleGetEventAnalysis:", error)
  }
}

export const fetchFedSpeakerSummary = async (
  eventName: string,
  date: string,
  getAccessTokenSilently: () => Promise<string>,
): Promise<FedSpeakerInfo[]> => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_MRKT_SERVER}/headlines/summarize-event?speaker=${encodeURIComponent(eventName)}&date=${date}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    )

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return data as FedSpeakerInfo[]
  } catch (error) {
    console.error("Error fetching Fed Speaker info:", error)
    throw error
  }
}
export const getEventSummary = async (
  eventName: string,
  date: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  try {
    const data = await fetchFedSpeakerSummary(
      eventName,
      date,
      getAccessTokenSilently,
    )
    return data
  } catch (error) {
    console.error("Error in handleGetEventSummary:", error)
  }
}
