export function parseHeadlineTime(headline: string): {
  relativeTime: string
  localTime: string
} {
  if (headline === undefined) return { relativeTime: "", localTime: "" }

  const splitIndex = headline.indexOf("]: ")

  // Check if the headline doesn't have a timestamp
  if (splitIndex === -1) {
    return { relativeTime: "No time", localTime: "No timestamp available" }
  }

  const dateTimeString = headline.substring(0, splitIndex + 1)
  const dateTimeStringWithoutBrackets = dateTimeString.slice(1, -1)

  // Create a date object directly from the Reuters timestamp
  const reutersTime = new Date(dateTimeStringWithoutBrackets)

  // Check if the parsed date is valid
  if (isNaN(reutersTime.getTime())) {
    return { relativeTime: "Invalid time", localTime: "Invalid timestamp" }
  }

  // Get current time
  const now = new Date()

  // Calculate the difference in milliseconds
  const diffInMs = now.getTime() - reutersTime.getTime()

  // Calculate the difference in minutes, accounting for potential timezone differences
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60))

  let relativeTime: string
  if (diffInMinutes < 1) {
    relativeTime = "Just now"
  } else if (diffInMinutes === 1) {
    relativeTime = "1 minute ago"
  } else if (diffInMinutes < 60) {
    relativeTime = `${diffInMinutes} minutes ago`
  } else if (diffInMinutes < 1440) {
    // Less than 24 hours
    const hours = Math.floor(diffInMinutes / 60)
    relativeTime = `${hours} hour${hours > 1 ? "s" : ""} ago`
  } else {
    const days = Math.floor(diffInMinutes / 1440)
    relativeTime = `${days} day${days > 1 ? "s" : ""} ago`
  }

  // Format localized time
  const localTime = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  }).format(reutersTime)

  return { relativeTime, localTime }
}

export function parseHeadlineTitle(headline: string) {
  if (headline === undefined) return
  const splitIndex = headline.indexOf("]: ")
  return splitIndex === -1 ? headline : headline.substring(splitIndex + 3)
}
