import clsx from "clsx"
import { MultiSelectCheckbox } from "./MultiSelectCheckbox"
import SimpleBar from "simplebar-react"

export interface MultiSelectOption {
  label: string
  value: string
  selected?: boolean
}

interface MultiSelectProps {
  options: MultiSelectOption[]
  selectedOptions: string[]
  onToggle: (id: string) => void
  className?: string
}

export const MultiSelect = ({
  options,
  selectedOptions,
  onToggle,
  className,
}: MultiSelectProps) => {
  const isSelected = (option: MultiSelectOption) => {
    if (option.value === "select-all") {
      return option.selected
    }
    return selectedOptions.includes(option.value)
  }

  return (
    <SimpleBar
      className={clsx(className, "max-h-[350px]")}
      data-testid="multi-select"
    >
      <div className="flex flex-col">
        {options.map((option) => (
          <MultiSelectCheckbox
            key={option.value}
            label={option.label}
            checked={isSelected(option) || false}
            onChange={() => onToggle(option.value)}
          />
        ))}
      </div>
    </SimpleBar>
  )
}
