import { useEffect, useRef } from "react"
import { Search, X, Loader2 } from "lucide-react"
import { useMarketHeadlines } from "@/providers"
import {
  parseHeadlineTime,
  parseHeadlineTitle,
} from "../../utilities/headlineParser"
import SimpleBar from "simplebar-react"
import { HeadlineItem } from "./HeadlineItem"
import { useInView } from "react-intersection-observer"

type SearchOverlayProps = {
  isOpen: boolean
  onClose: () => void
  highlightEnabled: boolean
}

export const SearchOverlay = ({
  isOpen,
  onClose,
  highlightEnabled,
}: SearchOverlayProps) => {
  const {
    searchQuery,
    setSearchQuery,
    searchHeadlines,
    searchResults,
    isSearching,
    searchNextPage,
    searchHasNextPage,
    isSearchingNextPage,
  } = useMarketHeadlines()

  const inputRef = useRef<HTMLInputElement>(null)

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
  })

  const handleClearSearch = () => {
    setSearchQuery("")
    searchHeadlines("")
    inputRef.current?.focus()
  }

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus()

      const handleEscape = (e: KeyboardEvent) => {
        if (e.key === "Escape") onClose()
      }

      document.addEventListener("keydown", handleEscape)
      return () => document.removeEventListener("keydown", handleEscape)
    }
  }, [isOpen, onClose])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchHeadlines(searchQuery)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  useEffect(() => {
    if (inView && searchHasNextPage && !isSearchingNextPage) {
      searchNextPage()
    }
  }, [inView, searchHasNextPage, isSearchingNextPage])

  if (!isOpen) return null

  return (
    <>
      <div
        onClick={onClose}
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50"
      />

      <div
        className="fixed inset-0 flex items-center justify-center z-50 p-4"
        onClick={onClose}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-full max-w-3xl rounded-lg shadow-2xl flex flex-col max-h-[800px]"
        >
          <div className="p-4 border rounded-md border-[#333] bg-obsidian/95 backdrop-blur-sm">
            <div className="flex items-center gap-3">
              <Search className="w-5 h-5 text-purple-400" />
              <input
                ref={inputRef}
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search headlines..."
                className="flex-1 bg-transparent text-white text-lg outline-none placeholder:text-gray-500"
              />
              {searchQuery && (
                <button
                  onClick={handleClearSearch}
                  className="flex items-center gap-1.5 px-3 py-1.5 
                           bg-[#222] hover:bg-[#333] 
                           rounded-md transition-colors text-sm text-gray-400 
                           hover:text-gray-300 border border-[#333]"
                >
                  <span className="mr-0.5">Clear</span>
                </button>
              )}
              <div className="w-px h-6 bg-[#333]" />
              <button
                onClick={onClose}
                className="p-2 hover:bg-[#333] rounded-md transition-colors"
                aria-label="Close search"
              >
                <X className="w-5 h-5 text-gray-300" strokeWidth={2} />
              </button>
            </div>
          </div>

          <div className="bg-transparent h-[20px]" />

          {searchResults.length > 0 ? (
            <div className="flex-1 min-h-[600px] border rounded-md border-[#333] bg-obsidian/95 backdrop-blur-sm">
              <SimpleBar style={{ maxHeight: "600px" }}>
                <div className="p-4">
                  <div className="space-y-1">
                    {searchResults.map((headline, index) => {
                      const { relativeTime, localTime } =
                        parseHeadlineTime(headline)
                      const headlineTitle = parseHeadlineTitle(headline)
                      if (!headlineTitle) return null

                      return (
                        <div
                          key={index}
                          className="p-3 rounded hover:bg-gray-800/50 
                                   transition-colors duration-200 group"
                        >
                          <HeadlineItem
                            headlineTitle={headlineTitle}
                            headlineTime={localTime}
                            relativeTime={relativeTime}
                            highlightEnabled={highlightEnabled}
                          />
                        </div>
                      )
                    })}

                    {(searchHasNextPage || isSearchingNextPage) && (
                      <div
                        ref={loadMoreRef}
                        className="flex justify-center py-4"
                      >
                        {isSearchingNextPage && (
                          <div className="flex items-center gap-2 text-gray-400">
                            <Loader2 className="w-4 h-4 animate-spin" />
                            <span>Loading more results...</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </SimpleBar>
            </div>
          ) : (
            <div className="flex-1 min-h-[600px] flex items-center justify-center">
              <div className="text-center">
                {isSearching ? (
                  <div>
                    <Loader2 className="w-8 h-8 mx-auto mb-3 animate-spin text-purple-400" />
                  </div>
                ) : searchQuery ? (
                  <div>
                    <Search className="w-8 h-8 mx-auto mb-3 text-gray-600" />
                    <div className="text-gray-400">No headlines found</div>
                  </div>
                ) : (
                  <div>
                    <Search className="w-8 h-8 mx-auto mb-3 text-gray-600" />
                    <div className="text-gray-400">
                      Start typing to search headlines
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
