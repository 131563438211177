import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useMemo } from "react"
import "react-loading-skeleton/dist/skeleton.css"
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { OverviewChartTypes } from "../constants/OverviewChartConstants"
import { format } from "date-fns"
import { HeadlineSentiment, SentimentBadge } from "../../homescreen"

interface SentimentChartProps {
  symbol: string
  title: string
  type: OverviewChartTypes
  data: any
}

export const SentimentChart = ({ title, type, data }: SentimentChartProps) => {
  const chartData = useMemo(() => {
    if (!data?.values) return []
    return data.values
      .map((item: any) => ({
        date: format(new Date(item.datetime), "MMM dd"),
        value: parseFloat(item.close),
      }))
      .reverse()
  }, [data])

  // Calculate custom domain for YAxis
  const yDomain = useMemo(() => {
    if (chartData.length === 0) return [0, 100]
    const values = chartData.map((item: any) => item.value)
    const min = Math.min(...values)
    const max = Math.max(...values)
    const padding = (max - min) * 0.2 // 10% padding
    return [min - padding, max + padding]
  }, [chartData])

  const renderContent = () => {
    return (
      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          data={chartData}
          margin={{ top: 5, right: 15, left: -10, bottom: 15 }}
        >
          <XAxis
            dataKey="date"
            stroke="#888"
            tick={{ fill: "#888", fontSize: 10 }}
            axisLine={{ stroke: "#333" }}
          />
          <YAxis
            stroke="#888"
            tick={{ fill: "#888", fontSize: 10 }}
            axisLine={{ stroke: "#333" }}
            domain={yDomain}
            allowDataOverflow={true}
            tickFormatter={(value) => value.toFixed(2)}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              backdropFilter: "blur(8px)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              fontSize: 12,
              padding: "8px",
            }}
            itemStyle={{ color: "#fff" }}
            labelStyle={{ color: "#888" }}
            formatter={(value: number) => [`${value.toFixed(2)}`, "Price"]}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke={"#3b82f6"}
            strokeWidth={1.5}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return (
    <div className="flex flex-col h-full w-full relative">
      <Card className="h-full bg-black border-none max-w-3xl rounded-xl shadow-xl relative z-10 overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div
            className={`absolute top-0 right-0 w-40 h-40 rounded-full filter blur-2xl opacity-40 ${
              type === "Bullish" ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
        </div>
        <div className="relative z-10 h-full backdrop-blur-xl bg-black/30">
          <CardHeader className="p-2">
            <CardTitle className="text-lg font-medium text-white flex justify-between items-center">
              {title}
              {SentimentBadge(type as unknown as HeadlineSentiment)}
            </CardTitle>
          </CardHeader>
          <CardContent className="p-0 h-[calc(100%-2rem)] pr-8">
            {renderContent()}
          </CardContent>
        </div>
      </Card>
    </div>
  )
}
