import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { useEffect, useState } from "react"
import { TooltipIcon } from "@/components/ui/tooltip_icon"
import { getOpenTradingSessions } from "../../utilities/timezone"
import worldMap from "../../../../../assets/world6.svg"
import { MarketTooltip } from "./MarketTooltip"
import { TooltipProvider } from "@/components/ui/tooltip"

export interface MapState {
  sydney: boolean
  tokyo: boolean
  london: boolean
  newYork: boolean
}

// Updated coordinates for world6.svg dimensions (4000x2000)
const MARKET_COORDINATES = {
  newYork: { x: 1000, y: 650 }, // New York
  london: { x: 2150, y: 550 }, // London
  tokyo: { x: 3200, y: 650 }, // Tokyo
  sydney: { x: 3400, y: 1600 }, // Sydney
}

const MARKET_INFO = {
  newYork: {
    name: "New York",
    hours: "8:00 AM - 5:00 PM EST",
    volume: "High" as const,
  },
  london: {
    name: "London",
    hours: "8:00 AM - 4:30 PM GMT",
    volume: "High" as const,
  },
  tokyo: {
    name: "Tokyo",
    hours: "9:00 AM - 6:00 PM JST",
    volume: "Medium" as const,
  },
  sydney: {
    name: "Sydney",
    hours: "9:00 AM - 5:00 PM AEST",
    volume: "Low" as const,
  },
}

const MarketDot = ({
  x,
  y,
  isActive,
  name,
  market,
}: {
  x: number
  y: number
  isActive: boolean
  name: string
  market: keyof typeof MARKET_INFO
}) => {
  const info = MARKET_INFO[market]

  return (
    <MarketTooltip name={info.name} hours={info.hours} volume={info.volume}>
      <g transform={`translate(${x},${y})`}>
        {isActive && (
          <circle r="80" className="fill-green-500 opacity-40 animate-ping" />
        )}
        <circle
          r="60"
          className={`${
            isActive ? "fill-green-500" : "fill-red-500"
          } transition-colors duration-300`}
          style={{
            stroke: isActive ? "#10B981" : "#EF4444",
            strokeWidth: "1px",
          }}
        />
      </g>
    </MarketTooltip>
  )
}

const Dot = ({ isActive }: { isActive: boolean }) => {
  return (
    <div
      className={`w-3 h-3 rounded-full ${
        isActive ? "bg-green-500" : "bg-red-500"
      }`}
    />
  )
}

export const Map = () => {
  const [isActive, setIsActive] = useState<MapState>({
    sydney: false,
    tokyo: false,
    london: false,
    newYork: false,
  })

  useEffect(() => {
    const updateSessions = () => {
      const openSessions = getOpenTradingSessions()
      setIsActive(openSessions as unknown as MapState)
    }

    updateSessions()
    const interval = setInterval(updateSessions, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <TooltipProvider>
      <Card className="h-full w-full bg-transparent border-none flex flex-col">
        <CardHeader className="p-2 flex flex-row">
          <div className="flex items-center gap-2">
            <h2 className="text-lg font-medium text-white">
              Global Markets Sessions
            </h2>
            <TooltipIcon
              tooltipTitle="Global MRKT Sessions"
              tooltipText="Live view of MRKT sessions across the globe."
              className="h-5 w-5 text-gray-400 hover:text-gray-600 text-purple-400"
            />
          </div>
        </CardHeader>
        <CardContent className="p-4 flex-grow flex flex-col relative">
          <div className="relative flex-grow flex items-center justify-center">
            <div className="w-[95%] h-[95%] relative">
              <img
                src={worldMap}
                alt="World Map"
                className="w-full h-full object-contain"
                style={{ mixBlendMode: "normal" }}
              />
              <svg
                className="absolute inset-0 w-full h-full"
                viewBox="0 0 4000 2000"
                preserveAspectRatio="xMidYMid meet"
                style={{ pointerEvents: "auto" }}
              >
                <g className="pointer-events-auto">
                  <MarketDot
                    x={MARKET_COORDINATES.newYork.x}
                    y={MARKET_COORDINATES.newYork.y}
                    isActive={isActive.newYork}
                    name="NY"
                    market="newYork"
                  />
                  <MarketDot
                    x={MARKET_COORDINATES.london.x}
                    y={MARKET_COORDINATES.london.y}
                    isActive={isActive.london}
                    name="LDN"
                    market="london"
                  />
                  <MarketDot
                    x={MARKET_COORDINATES.tokyo.x}
                    y={MARKET_COORDINATES.tokyo.y}
                    isActive={isActive.tokyo}
                    name="TKY"
                    market="tokyo"
                  />
                  <MarketDot
                    x={MARKET_COORDINATES.sydney.x}
                    y={MARKET_COORDINATES.sydney.y}
                    isActive={isActive.sydney}
                    name="SYD"
                    market="sydney"
                  />
                </g>
              </svg>
            </div>
          </div>

          {/* Legend section */}
          <div className="flex justify-center items-center space-x-8 pb-2">
            <div className="flex items-center space-x-2">
              <Dot isActive={isActive.newYork} />
              <span className="text-white text-sm">NY</span>
            </div>
            <div className="flex items-center space-x-2">
              <Dot isActive={isActive.london} />
              <span className="text-white text-sm">LDN</span>
            </div>
            <div className="flex items-center space-x-2">
              <Dot isActive={isActive.tokyo} />
              <span className="text-white text-sm">TKY</span>
            </div>
            <div className="flex items-center space-x-2">
              <Dot isActive={isActive.sydney} />
              <span className="text-white text-sm">SYD</span>
            </div>
          </div>
        </CardContent>
      </Card>
    </TooltipProvider>
  )
}
