import { createContext, useContext, ReactNode, useState } from "react"

type LoadingContextType = {
  setLoading: (key: string, isLoading: boolean) => void
  isLoading: (key?: string) => boolean
}

const LoadingContext = createContext<LoadingContextType | null>(null)

export function LoadingProvider({ children }: { children: ReactNode }) {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {},
  )

  const setLoading = (key: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [key]: isLoading }))
  }

  const isLoading = (key?: string) => {
    if (key) return loadingStates[key]
    return Object.values(loadingStates).some((state) => state)
  }

  return (
    <LoadingContext.Provider value={{ setLoading, isLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context)
    throw new Error("useLoading must be used within LoadingProvider")
  return context
}
