import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"

type PaginationMetadata = {
  currentPage: number
  totalPages: number
  totalItems: number
  itemsPerPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
}

type MarketHeadlinesResponse = {
  headlines: any[]
  pagination: PaginationMetadata
}

type MarketHeadlinesContextProps = {
  marketHeadlines: string[]
  setMarketHeadlines: Dispatch<SetStateAction<string[]>>
  error: Error | null
  isLoading: boolean
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
  isFetchingNextPage: boolean
  searchHeadlines: (query: string) => Promise<void>
  isSearching: boolean
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  searchResults: string[]
  searchNextPage: () => Promise<void>
  searchHasNextPage: boolean
  isSearchingNextPage: boolean
}

const MarketHeadlinesContext = createContext<
  MarketHeadlinesContextProps | undefined
>(undefined)

type MarketHeadlinesProviderProps = {
  children: ReactNode
}

const fetchRecentHeadlines = async (
  getAccessTokenSilently: () => Promise<string>,
  page: number = 1,
  limit: number = 50,
  search?: string,
): Promise<MarketHeadlinesResponse> => {
  const searchParam = search ? `&search=${encodeURIComponent(search)}` : ""
  const response = await fetch(
    `${import.meta.env.VITE_MRKT_SERVER}/headlines/all?page=${page}&limit=${limit}${searchParam}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    },
  )
  return await response.json()
}

export const MarketHeadlinesProvider = ({
  children,
}: MarketHeadlinesProviderProps) => {
  const [marketHeadlines, setMarketHeadlines] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const [searchQuery, setSearchQuery] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState<string[]>([])
  const [searchPage, setSearchPage] = useState(1)
  const [searchHasNextPage, setSearchHasNextPage] = useState(false)
  const [isSearchingNextPage, setIsSearchingNextPage] = useState(false)

  const { data, error, isLoading } = useQuery({
    queryKey: ["marketHeadlines", 1],
    queryFn: () => fetchRecentHeadlines(getAccessTokenSilently),
  })

  useEffect(() => {
    if (data) {
      const headlines = data.headlines.map((headline: any) => headline?.text)
      setMarketHeadlines(headlines)
      setHasNextPage(data.pagination.hasNextPage)
      setCurrentPage(data.pagination.currentPage)
    }
  }, [data])

  const fetchNextPage = async () => {
    if (!hasNextPage || isFetchingNextPage) return

    setIsFetchingNextPage(true)
    try {
      const nextPageData = await fetchRecentHeadlines(
        getAccessTokenSilently,
        currentPage + 1,
      )
      const newHeadlines = nextPageData.headlines.map(
        (headline: any) => headline?.text,
      )

      setMarketHeadlines((prev) => [...prev, ...newHeadlines])
      setHasNextPage(nextPageData.pagination.hasNextPage)
      setCurrentPage(nextPageData.pagination.currentPage)
    } catch (err) {
      console.error("Error fetching next page:", err)
    } finally {
      setIsFetchingNextPage(false)
    }
  }

  const searchHeadlines = async (query: string) => {
    if (!query) {
      setSearchResults([])
      return
    }

    setIsSearching(true)
    try {
      const searchData = await fetchRecentHeadlines(
        getAccessTokenSilently,
        1,
        50,
        query,
      )
      setSearchResults(searchData.headlines.map((h: any) => h?.text))
      setSearchHasNextPage(searchData.pagination.hasNextPage)
      setSearchPage(1)
    } catch (err) {
      console.error("Error searching headlines:", err)
    } finally {
      setIsSearching(false)
    }
  }

  const searchNextPage = async () => {
    if (!searchHasNextPage || isSearchingNextPage || !searchQuery) return

    setIsSearchingNextPage(true)
    try {
      const nextPageData = await fetchRecentHeadlines(
        getAccessTokenSilently,
        searchPage + 1,
        50,
        searchQuery,
      )
      const newHeadlines = nextPageData.headlines.map(
        (headline: any) => headline?.text,
      )

      setSearchResults((prev) => [...prev, ...newHeadlines])
      setSearchHasNextPage(nextPageData.pagination.hasNextPage)
      setSearchPage(nextPageData.pagination.currentPage)
    } catch (err) {
      console.error("Error fetching next page of search results:", err)
    } finally {
      setIsSearchingNextPage(false)
    }
  }

  return (
    <MarketHeadlinesContext.Provider
      value={{
        marketHeadlines,
        setMarketHeadlines,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        searchHeadlines,
        isSearching,
        searchQuery,
        setSearchQuery,
        searchResults,
        searchNextPage,
        searchHasNextPage,
        isSearchingNextPage,
      }}
    >
      {children}
    </MarketHeadlinesContext.Provider>
  )
}

export const useMarketHeadlines = (): MarketHeadlinesContextProps => {
  const context = useContext(MarketHeadlinesContext)
  if (!context) {
    throw new Error(
      "useMarketHeadlines must be used within a MarketHeadlinesProvider",
    )
  }
  return context
}
