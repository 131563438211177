import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import clsx from "clsx"
import { ReactNode } from "react"

import { FiX, FiChevronDown } from "react-icons/fi"

type FilterPillProps = {
  children: (props: { close: () => void }) => ReactNode
  label: ReactNode
  isActive?: boolean
  onClear?: () => void
  fullHeight?: boolean
  className?: string
}

type PillProps = {
  children: ReactNode
  className?: string
}

export const Pill = ({ children, className, ...props }: PillProps) => {
  return (
    <div
      className={clsx(
        "text-primary flex items-center gap-2 rounded-md px-4 text-sm font-normal focus:outline-none",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const FilterPill = ({
  children,
  label,
  isActive,
  onClear,
  fullHeight,
  className,
}: FilterPillProps) => {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <PopoverButton className="focus:outline-none">
            <Pill
              className={clsx(className, {
                "bg-[#201f21]": !open && !isActive,
                "bg-[#4a4749]": (open && !isActive) || (open && isActive),
                "overflow-hidden bg-gray-600/50": !open && isActive,
                "text-white": isActive,
                "py-0": fullHeight,
                "py-2": !fullHeight,
              })}
            >
              <span className="overflow-hidden whitespace-nowrap text-white font-medium text-[16px]">
                {label}
              </span>
              {isActive ? (
                <div
                  data-testid="clear-icon"
                  className="px-0.5"
                  onClick={(e) => {
                    e.stopPropagation()
                    onClear?.()
                    close()
                  }}
                >
                  <FiX className="text-white" size="1.5rem" />
                </div>
              ) : (
                <div data-testid="down-chevron-icon">
                  <FiChevronDown
                    size="1.5rem"
                    className={clsx(
                      "mx-[3px] transition text-white duration-75 ease-in",
                      {
                        "text-white rotate-180": open,
                      },
                    )}
                  />
                </div>
              )}
            </Pill>
          </PopoverButton>
          <PopoverPanel anchor="bottom start" className="flex flex-col mt-3">
            {children({ close })}
          </PopoverPanel>
        </>
      )}
    </Popover>
  )
}
