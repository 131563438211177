import { useState } from "react"
import { Link, useRouterState } from "@tanstack/react-router"
import { clsx } from "clsx"
import { Bars3Icon, HomeIcon, XMarkIcon } from "@heroicons/react/24/outline"
import AccountDropdown from "./AccountDropdown"

const BetaModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-black border border-[#333] rounded-lg p-6 max-w-2xl w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>

        <div className="flex items-center gap-3 mb-6">
          <h2 className="text-2xl font-semibold text-white">
            <span role="img" aria-label="rocket" className="text-2xl mr-2">
              🚀
            </span>
            Welcome to MRKT{" "}
            <span className="bg-violet-600/20 text-purple-400 text-xs px-2 py-0.5 rounded-md font-medium tracking-wide border border-purple-500/30">
              BETA
            </span>
          </h2>
        </div>

        <div className="space-y-4 text-gray-300">
          <p className="text-lg">
            MRKT is currently in beta, and we're working hard to create the
            ultimate platform for traders and investors. Our mission is to
            provide sophisticated market analysis tools that are both powerful
            and accessible.
          </p>

          <p className="text-lg font-medium text-white">
            During this beta phase, we're actively:
          </p>

          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Developing new features and improvements</li>
            <li>Optimizing performance and user experience</li>
            <li>Gathering valuable feedback from our users</li>
            <li>Rolling out regular updates and enhancements</li>
          </ul>

          <p className="text-lg">
            Your feedback is crucial in shaping MRKT's future. We'd love to hear
            your thoughts, suggestions, and feature requests.
          </p>

          <div className="mt-8">
            <a
              href="https://mrkt.featurebase.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-violet-700 hover:bg-violet-600 text-white rounded-md transition-colors font-medium"
            >
              Submit Feedback
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const NAVIGATION = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon },
  // { name: "Sentiment", href: "/sentiment", icon: BookOpenIcon },
]

export const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [betaModalOpen, setBetaModalOpen] = useState(false)
  const router = useRouterState()

  return (
    <>
      <nav className="bg-black pt-4">
        <div className="mx-auto px-12 sm:px-6 lg:px-12">
          <div className="flex h-16 items-center justify-between">
            <div className="flex-shrink-0 flex items-center gap-2">
              <h1 className="text-white text-4xl font-normal">MRKT</h1>
              <button
                onClick={() => setBetaModalOpen(true)}
                className="bg-purple-600/20 text-purple-400 text-xs px-2 py-0.5 rounded-md font-medium tracking-wide transform translate-y-1 border border-purple-500/30 hover:bg-purple-600/30 transition-colors"
              >
                BETA
              </button>
            </div>
            <div className="flex-grow flex justify-center">
              <div className="hidden sm:flex space-x-8 mr-12">
                {NAVIGATION.map((item) => {
                  const isActive = router.location.pathname === item.href
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={clsx(
                        isActive
                          ? "border-white border-b-3 text-white"
                          : "border-transparent text-gray-300 hover:border-gray-300 hover:text-white",
                        "inline-flex items-center border-b-2 px-1 pt-1 text-lg font-medium",
                      )}
                    >
                      {item.name}
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="flex items-center">
              <div className="relative p-2">
                <AccountDropdown />
              </div>
              <div className="sm:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {mobileMenuOpen && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="space-y-1 pb-3 pt-2 px-12 sm:px-6 lg:px-12">
              {NAVIGATION.map((item) => {
                const isActive = router.location.pathname === item.href
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={clsx(
                      isActive
                        ? "bg-gray/50 border-indigo-500 text-white"
                        : "border-transparent text-gray-300 hover:bg-gray-100 hover:border-gray-300 hover:text-white",
                      "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                    )}
                  >
                    {item.name}
                  </Link>
                )
              })}
            </div>
          </div>
        )}
      </nav>

      <BetaModal
        isOpen={betaModalOpen}
        onClose={() => setBetaModalOpen(false)}
      />
    </>
  )
}
