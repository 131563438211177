import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { TooltipIcon } from "@/components/ui/tooltip_icon"
import { useEconomicCalendar } from "@/providers/EconomicCalendarProvider"
import { format, parseISO } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from "recharts"
import { generateHourlyData, sessions, type Session } from "./utils"

const EventMarker = ({ cx, cy, payload }: any) => {
  if (payload.events && payload.events !== "") {
    return (
      <g>
        <circle cx={cx} cy={cy} r={4} fill="white" />
        <circle cx={cx} cy={cy} r={8} fill="pink" fillOpacity={0.2} />
      </g>
    )
  }
  return null
}

const SessionMarker = ({ cx, cy, payload }: any) => {
  if (payload.sessionOpen || payload.specialOpen) {
    // Find which session is opening
    let openingSession: string = "Unknown"
    if (payload.sessionOpen) {
      const foundSession = sessions.find((s) => s.start === payload.utcTime)
      if (foundSession) {
        openingSession = foundSession.name
      }
    }
    if (payload.specialOpen) {
      openingSession = payload.specialOpen
    }

    // Get current UTC time
    const now = new Date()
    const currentUtcHour = now.getUTCHours()

    // Find the session data
    const sessionData = sessions.find((s) => s.name === openingSession)

    // Check if the session is active based on UTC time
    const isActive = sessionData
      ? sessionData.start <= sessionData.end
        ? currentUtcHour >= sessionData.start &&
          currentUtcHour < sessionData.end
        : currentUtcHour >= sessionData.start ||
          currentUtcHour < sessionData.end
      : false

    // Don't render if we don't know which session is opening
    if (openingSession === "Unknown") return null

    // Position labels based on session name
    let labelPosition
    if (openingSession === "New York") {
      labelPosition = { x: cx - 22, anchor: "end", y: cy } // Position New York label to the left
    } else if (openingSession === "NYSE") {
      labelPosition = { x: cx + 0, anchor: "bottom", y: cy + 25 } // Position NYSE label to the left
    } else {
      labelPosition = { x: cx + 22, anchor: "middle", y: cy - 10 } // Default position for other labels
    }
    return (
      <g>
        {/* Main dot */}
        <circle cx={cx} cy={cy} r={4} fill={isActive ? "#4ade80" : "#ef4444"} />
        {/* Outer ring */}
        <circle
          cx={cx}
          cy={cy}
          r={8}
          fill={isActive ? "#4ade80" : "#ef4444"}
          fillOpacity={0.2}
        />
      </g>
    )
  }
  return null
}

export const SessionVolume = () => {
  const { economicCalendarData } = useEconomicCalendar()
  const [selectedDate, setSelectedDate] = useState<string>("")
  const [currentTime, setCurrentTime] = useState(() => {
    const now = new Date()
    return now.getHours()
  })

  // Get unique dates from the economic calendar data
  const availableDates = useMemo(() => {
    const dates = economicCalendarData.map((event: any) =>
      format(parseISO(event.dateUtc), "yyyy-MM-dd"),
    )
    return Array.from(new Set(dates)).sort() as string[]
  }, [economicCalendarData])

  // Update selected date whenever the date changes or available dates change
  useEffect(() => {
    const today = format(new Date(), "yyyy-MM-dd")

    if (availableDates.includes(today)) {
      setSelectedDate(today)
    } else {
      // Find the next available date after today
      const nextDate = availableDates.find((date) => date > today)
      if (nextDate) {
        setSelectedDate(nextDate)
      } else if (availableDates.length > 0) {
        // If no future dates, use the latest available date
        setSelectedDate(availableDates[availableDates.length - 1])
      }
    }
  }, [availableDates])

  const selectedEvents = useMemo(() => {
    if (!selectedDate) return []
    const startOfDay = new Date(selectedDate)
    const endOfDay = new Date(selectedDate)
    endOfDay.setDate(endOfDay.getDate() + 1)

    return economicCalendarData.filter((event: any) => {
      const eventDate = new Date(event.dateUtc)
      return eventDate >= startOfDay && eventDate < endOfDay
    })
  }, [economicCalendarData, selectedDate])

  const hourlyData = useMemo(
    () => generateHourlyData(selectedEvents),
    [selectedEvents],
  )

  // Update current time every minute
  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date()
      setCurrentTime(now.getHours())
    }

    // Update immediately
    updateCurrentTime()

    // Then set interval for updates
    const interval = setInterval(updateCurrentTime, 60000)
    return () => clearInterval(interval)
  }, [])

  // Format current time for the label
  const formatCurrentTime = (time: number) => {
    const period = time >= 12 ? "PM" : "AM"
    const displayHour = time % 12 || 12
    return `${displayHour}${period}`
  }

  const content = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload
      const timeZone = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2]

      // Determine which session is opening
      let openingSession = ""
      if (data.sessionOpen) {
        openingSession = data.activeSessions
          .split(", ")
          .find((session: string) => {
            const sessionData = sessions.find(
              (s: Session) => s.name === session,
            )
            return sessionData?.start === data.utcTime
          })
      }
      if (data.specialOpen) {
        openingSession = data.specialOpen
      }

      return (
        <div className="backdrop-blur-md bg-black/30 text-white p-2 rounded-lg shadow-lg border border-white/10 w-[300px] h-auto">
          <p className="font-extrabold text-md mb-2 text-purple-400">
            {`${data.localTime}:00 ${timeZone}`}
            {openingSession && (
              <span className="text-green-400 ml-2 text-md font-medium">
                ({openingSession} Open)
              </span>
            )}
          </p>
          <div className="space-y-1 text-sm">
            <p className="flex justify-between">
              <span className="text-gray-300">Volatility:</span>
              <span className="font-medium">{data.volatility.toFixed(2)}</span>
            </p>
            <div>
              <p className="text-gray-300">Active Sessions:</p>
              <p className="font-medium">{data.activeSessions}</p>
            </div>
            <div className="pt-2">
              <p className="text-gray-300 mb-1">Events:</p>
              <p className="font-medium text-sm leading-tight">
                {data.events || "None"}
              </p>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <Card className="h-full w-full bg-[#111] border-none flex flex-col relative overflow-hidden">
      <CardHeader className="flex flex-row justify-between border-gray-700">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-medium text-white">
            Forecasted Volume & Volatility
          </h2>
          <TooltipIcon
            tooltipTitle="Volume & Volatility Forecast"
            tooltipText="Shows predicted trading volume and price volatility for upcoming market sessions based on historical data and market events."
            className="h-5 w-5 text-gray-400 hover:text-gray-600 text-purple-400"
          />
        </div>
        <Select
          value={selectedDate}
          onValueChange={(value) => setSelectedDate(value)}
        >
          <SelectTrigger className="w-[180px] bg-[#222] border-[#333] text-white rounded-md text-md">
            <SelectValue placeholder="Select date" />
          </SelectTrigger>
          <SelectContent className="bg-[#222] border-[#333]">
            {availableDates.map((date) => (
              <SelectItem
                key={date as string}
                value={date as string}
                className="text-white hover:bg-gray-700"
              >
                {format(parseISO(date as string), "MMM d, yyyy")}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent className="flex-grow overflow-hidden relative mt-3">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={hourlyData}
            margin={{ left: -30, top: 10, right: 10 }}
          >
            <XAxis
              dataKey="localTime"
              tickFormatter={(time) => `${time}:00`}
              fontSize={12}
              stroke="#888"
            />
            <YAxis
              domain={[0, 5]}
              ticks={[0, 1, 2, 3, 4, 5]}
              fontSize={12}
              stroke="#888"
            />
            <Tooltip content={content} />
            {selectedDate === format(new Date(), "yyyy-MM-dd") && (
              <ReferenceLine
                x={currentTime}
                stroke="#4ade80"
                strokeDasharray="1 1"
                strokeWidth={1}
                isFront={true}
              />
            )}
            <Line
              type="monotone"
              dataKey="volatility"
              stroke="#a78bfa"
              name="Volatility"
              dot={<EventMarker />}
              strokeWidth={1}
            />
            <Line
              type="monotone"
              dataKey="volatility"
              stroke="transparent"
              dot={<SessionMarker />}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}
