import { format } from "date-fns"

export const getFormattedDate = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const date = new Date()
  const formattedDate = date.toLocaleDateString("en-US", options)

  // Custom suffix for day
  const day = date.getDate()
  const daySuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th"
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`)
}

// get day & time based on this
export function getFormattedDateFromString(dateString?: String) {
  if (dateString === undefined) return
  const parsedDateTime = dateString.slice(1, -1)
  const dateObject = new Date(parsedDateTime)
  const formattedDate = format(dateObject, "EEEE, h:mma")

  return formattedDate
}

export const getDayDateFormat = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // Allowed values: 'long', 'short', 'narrow'
    year: "numeric", // Allowed values: 'numeric', '2-digit'
    month: "long", // Allowed values: 'numeric', '2-digit', 'long', 'short', 'narrow'
    day: "numeric", // Allowed values: 'numeric', '2-digit'
  }

  return new Date(dateString).toLocaleDateString("en-US", options)
}

export function formatHeadlineTime(timeString: string): string {
  // Remove brackets and split the string
  const [datePart, timePart] = timeString.replace(/[\[\]]/g, "").split(", ")

  // Parse the date
  const [month, day, year] = datePart.split(" ")

  // Map short month names to full names
  const monthNames = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  }

  // Construct the formatted date string
  return `${monthNames[month as keyof typeof monthNames]} ${day} ${year}, ${timePart}`
}

export function formatEventDate(dateString: string): string {
  // Create date object from UTC string
  const date = new Date(dateString)

  // Format the date in user's local timezone
  const month = date.toLocaleString("en-US", { month: "short" })
  const day = date.getDate().toString().padStart(2, "0")
  const year = date.getFullYear()
  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")

  // Get timezone abbreviation
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzAbbr = new Date()
    .toLocaleTimeString("en-US", { timeZoneName: "short" })
    .split(" ")[2]

  return `${month} ${day} ${year}, ${hours}:${minutes}:${seconds} ${tzAbbr}`
}
